import React, { useState } from 'react';
import {
  Button,
  ModalPortal,
  RadioButton,
  RadioButtonGroup,
} from '@alpha-recycling/component-library';

import { useSetGradingAndTerms } from 'shared/mutations';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { layers } from 'theme';
import { useTypedIntl } from '../../locale/messages';
import { Content } from '../../SetYourApp.styles';

interface Props {
  onSetAppClick: (gradingSelected: boolean) => void;
}

export const GradingSelectStep = ({ onSetAppClick }: Props) => {
  const intl = useTypedIntl();
  const [volume, setVolume] = useState<string>();
  const [type, setType] = useState<string>();
  const dispatch = useAppDispatch();
  const setGradingAndTerms = useSetGradingAndTerms();
  const typeOptions = [
    {
      label: intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Type.0' }),
      value: '0',
    },
    {
      label: intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Type.1' }),
      value: '1',
    },
    {
      label: intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Type.2' }),
      value: '2',
    },
  ];
  const volumeOptions = [
    {
      label: intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Volume.0' }),
      value: '0',
    },
    {
      label: intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Volume.1' }),
      value: '1',
    },
  ];
  const isGradingSelected = type === '1' || (type === '2' && volume === '0');

  const setYourAppHandler = async () => {
    onSetAppClick(isGradingSelected);
    if (!isGradingSelected) return;
    try {
      await setGradingAndTerms.mutateAsync({
        grading: isGradingSelected,
      });
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  return (
    <ModalPortal
      isOpen
      zIndex={layers.modal}
      header={intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Header' })}
      data-cy="set-app-first-step"
      id="set-app-first-step"
      rightButtons={[
        <Button
          onClick={setYourAppHandler}
          content="text"
          variant="outline"
          id="set-app-first-step-button"
          data-cy="set-app-first-step-button"
          label={intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Button' })}
          disabled={!type || (type === '2' && !volume)}
        />,
      ]}
    >
      <Content>
        <RadioButtonGroup
          name="type"
          legendText={intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Type.Label' })}
          data-cy="set-app-first-step-type"
          valueSelected={type}
        >
          {typeOptions.map(({ label, value }) => (
            <RadioButton
              id={`type_${value}`}
              labelText={label}
              value={value}
              onChange={e => setType(e.target.value)}
              data-cy={`set-app-first-step-type-${value}`}
            />
          ))}
        </RadioButtonGroup>
        {type === '2' && (
          <RadioButtonGroup
            name="volume"
            legendText={intl.formatMessage({ id: 'SetYourApp.GradingSelectStep.Volume.Label' })}
            data-cy="set-app-first-step-volume"
            valueSelected={volume}
          >
            {volumeOptions.map(({ label, value }) => (
              <RadioButton
                id={`volume_${value}`}
                labelText={label}
                value={value}
                onChange={e => setVolume(e.target.value)}
                data-cy={`set-app-first-step-volume-${value}`}
              />
            ))}
          </RadioButtonGroup>
        )}
      </Content>
    </ModalPortal>
  );
};
