import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Status, Tag, Tooltip } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';
import { ColumnDef } from '@tanstack/react-table';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  AlphamartIntlProvider,
  withAlphamartIntlProvider,
} from 'components/shared/AlphamartIntlProvider';
import { ContextMenu } from 'components/shared/ContextMenu';
import { HedgePricesGroup } from 'components/shared/HedgePricesGroup/HedgePricesGroup';
import { MobileStatus, MobileStatusWrapper } from 'components/shared/List';
import { DataList } from 'components/shared/List/DataList';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelLayout } from 'components/shared/PanelLayout/PanelLayout';
import { ProtectedArea } from 'components/shared/ProtectedArea/ProtectedArea';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { resolveHedgeStatus } from 'helpers/hedges/resolveHedgeStatus';
import { FilterableModules, PERMISSIONS, SHARED, SortType } from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import {
  useAlphamartNavigate,
  useAppTable,
  useHedgeEditActions,
  useMediaQuery,
} from 'shared/hooks';
import { HedgeListItem, ItemAction, Status as StatusType } from 'shared/types';
import { clearHedgesAction, fetchHedges } from 'store/hedgesSlice';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { layers, MEDIA_QUERY, theme } from 'theme';
import { HedgesFilters } from './HedgesFilters';
import { AssignHedgeModal } from '../AssignHedge/AssignHedgeModal';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  TypedIntlShape,
  useTypedIntl,
} from '../locale/messages';

export const DateColumn = styled.div`
  width: 100px;
  white-space: normal;
`;

const UsersListItem = (intl: TypedIntlShape, hedge: HedgeListItem) => {
  let tooltipContent = hedge.relatedUsers
    .slice(0, 10)
    .reduce((acc, curr) => `${acc}${acc && ', '}${curr.firstName} ${curr.lastName}`, '');
  if (hedge.relatedUsers.length > 10) {
    tooltipContent += `, ${hedge.relatedUsers.length - 10} ${intl.formatMessage({
      id: 'HedgesList.Users.More',
    })}`;
  }

  return (
    <Tooltip
      label={tooltipContent}
      hidden={hedge.relatedUsers.length <= 1}
      position="top"
      zIndex={layers.tooltip}
    >
      {hedge.relatedUsers.length ? (
        <Tag
          size="big"
          title={
            hedge.relatedUsers.length === 1
              ? `${hedge.relatedUsers[0].firstName} ${hedge.relatedUsers[0].lastName}`
              : `${hedge.relatedUsers.length} ${intl.formatMessage({ id: 'HedgesList.Users' })}`
          }
        />
      ) : (
        SHARED.LONG_DASH
      )}
    </Tooltip>
  );
};

const HedgesListComponent = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const intl = useTypedIntl();
  const hedges = useAppSelector(state => state.hedges);
  const listComponent = useAppSelector(state => state.listComponent);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { actionsPending, handleUpdate, handleUnassign, handleRemove, handleAssign } =
    useHedgeEditActions();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.HEDGES]);
  const isMaxSm = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isLg = useMediaQuery(MEDIA_QUERY.LG);
  const isXxl = useMediaQuery(MEDIA_QUERY.XXL);

  useDeepCompareEffect(() => {
    if (!actionsPending) {
      dispatch(
        fetchHedges(listComponent.pageIndex + 1, listComponent.pageSize, {
          ...savedFilters.data,
          orderBy: savedFilters.data.placedOn ? HedgeOrderBy.PLACED_AT : HedgeOrderBy.EXPIRES_AT,
          sortBy: SortType.DESC,
          activeFirst: !savedFilters.data.placedOn,
        }),
      );
    }

    return () => {
      dispatch(clearHedgesAction());
    };
  }, [listComponent.pageIndex, listComponent.pageSize, savedFilters?.data, actionsPending]);

  const hedgesList = useMemo(
    () =>
      hedges.list.map(h => ({
        color: !isMd && resolveStatusColor(resolveHedgeStatus(h)),
        ...h,
      })),
    [isMd, hedges.list],
  );

  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const createHedge = () => navigate('/hedges/create');
  const handleRowClick = (item: HedgeListItem) => {
    navigate(`/hedges/${item.id}`);
  };

  const actions: ItemAction[] = [
    {
      label: <FormattedMessage id="Global.Update" />,
      onClick: handleUpdate,
      dataCy: item => `update-${item.id}`,
      visible: authorize(PERMISSIONS.HEDGES.UPDATE),
    },
    {
      label: <FormattedMessage id="Global.Unassign" />,
      onClick: handleUnassign,
      dataCy: item => `unassign-${item.id}`,
      visible: item => authorize(PERMISSIONS.HEDGES.UNASSIGN) && !!item.relatedUsersCount,
    },
    {
      label: <FormattedMessage id="Global.Remove" />,
      onClick: handleRemove,
      dataCy: item => `delete-${item.id}`,
      visible: authorize(PERMISSIONS.HEDGES.REMOVE),
    },
    {
      label: <FormattedMessage id="HedgeAssign.Action.Name" />,
      onClick: hedge =>
        handleAssign(hedge, () =>
          dispatch(
            fetchHedges(listComponent.pageIndex + 1, listComponent.pageSize, savedFilters.data),
          ),
        ),
      dataCy: item => `assign-${item.id}`,
      visible: item =>
        authorize(PERMISSIONS.HEDGES.ASSIGN.ACTION) &&
        resolveHedgeStatus(item) === StatusType.ACTIVE,
    },
  ];

  const canSeeHedgePrices = authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);
  const onFiltersToggle = () => setFiltersOpen(!filtersOpen);

  const onFiltersChanged = newFilters => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.HEDGES,
        values: newFilters,
      }),
    );
    setPageIndex(0);
  };

  const columns: ColumnDef<HedgeListItem>[] = [
    {
      id: 'status',
      header: intl.formatMessage({ id: 'HedgesList.TableHeader.Status' }),
      cell: ({ row }) => {
        const status = resolveHedgeStatus(row.original);
        return (
          <Status
            type={resolveStatusColor(status)}
            value={intl.formatMessage({
              id: `Global.Status.${status}`,
            })}
            size="big"
          />
        );
      },
    },
    {
      id: 'companyName',
      header: intl.formatMessage({ id: 'HedgesList.TableHeader.Company' }),
      cell: ({ row }) => (
        <MobileStatusWrapper>
          {!isLg && (
            <MobileStatus
              theme={theme}
              state={resolveStatusColor(resolveHedgeStatus(row.original))}
            />
          )}
          {row.original.companyName || SHARED.LONG_DASH}
        </MobileStatusWrapper>
      ),
    },
    {
      id: 'assignedUsers',
      header: intl.formatMessage({ id: 'HedgesList.TableHeader.AssignedUsers' }),
      cell: ({ row }) => UsersListItem(intl, row.original),
    },
    {
      id: 'name',
      header: intl.formatMessage({ id: 'HedgesList.TableHeader.Name' }),
      cell: ({ row }) => `${row.original.name} ${row.original.relatedLot || ''}`,
    },
    {
      id: 'hedgePrices',
      header: intl.formatMessage({ id: 'HedgesList.TableHeader.HedgePrices' }),
      cell: ({ row }) => <HedgePricesGroup hedge={row.original} />,
    },
    {
      id: 'placedAt',
      header: intl.formatMessage({ id: 'HedgesList.TableHeader.PlacedAt' }),
      cell: ({ row }) => <DateColumn>{formatDateWithTime(row.original.placedAt)}</DateColumn>,
    },
    {
      id: 'expiresAt',
      header: intl.formatMessage({ id: 'HedgesList.TableHeader.Expiration' }),
      cell: ({ row }) => <DateColumn>{formatDateWithTime(row.original.expiresAt)}</DateColumn>,
    },
    {
      id: 'actions',
      header: '',
      cell: ({ row }) => (
        <ContextMenu
          offsetX={-60}
          trigger={
            <Button data-cy="actionButton" iconName="ThreeDots" variant="plain" content="icon" />
          }
        >
          {actions.map(
            action =>
              (typeof action.visible === 'function'
                ? (action.visible as (item?: HedgeListItem | undefined) => boolean)(row.original)
                : action.visible) && (
                <button
                  type="button"
                  onClick={() => action.onClick(row.original)}
                  key={action.id}
                  data-cy={action.dataCy?.(row.original)}
                >
                  {action.label}
                </button>
              ),
          )}
        </ContextMenu>
      ),
    },
  ];

  const table = useAppTable({
    columns,
    data: hedgesList,
    pageCount: Math.ceil(hedges.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    manualPagination: true,
    state: {
      columnVisibility: {
        status: isLg,
        companyName: authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_COMPANY),
        assignedUsers: !isMaxSm,
        relatedLot: isXxl,
        placedAt: isLg,
        expiresAt: isLg,
        hedgePrices: canSeeHedgePrices,
        actions:
          authorize(PERMISSIONS.HEDGES.UPDATE) ||
          authorize(PERMISSIONS.HEDGES.REMOVE) ||
          authorize(PERMISSIONS.HEDGES.UNASSIGN) ||
          authorize(PERMISSIONS.HEDGES.ASSIGN.ACTION),
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
    },
  });

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelLayout>
        <PanelHeader title={<FormattedMessage id="HedgesList.Header" />}>
          <ProtectedArea permission={PERMISSIONS.HEDGES.CREATE}>
            <Button
              onClick={createHedge}
              content="text"
              variant="filled"
              label={intl.formatMessage({ id: 'Global.Create' })}
            />
          </ProtectedArea>
        </PanelHeader>
        <PanelContent>
          <PanelContentMain>
            <SearchBar
              searchInputVisible={false}
              onFiltersToggle={onFiltersToggle}
              savedFilters={savedFilters}
            />
            <DataList table={table} isLoading={hedges.isPending} onRowClicked={handleRowClick} />
          </PanelContentMain>
          <PanelContentSidebar
            header={<FormattedMessage id="Global.Filters" />}
            headerIcon="Filter"
            open={filtersOpen}
            onSidebarClosed={onFiltersToggle}
          >
            <HedgesFilters onFiltersChanged={onFiltersChanged} onFiltersApplied={onFiltersToggle} />
          </PanelContentSidebar>
        </PanelContent>
      </PanelLayout>
      <Outlet />
      <AssignHedgeModal />
    </AlphamartIntlProvider>
  );
};

export const HedgesList = withAlphamartIntlProvider(HedgesListComponent, messages);
