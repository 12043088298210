import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Status } from '@alpha-recycling/component-library';
import { ColumnDef } from '@tanstack/react-table';
import { isNil } from 'lodash';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { DataList } from 'components/shared/List/DataList';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelLayout } from 'components/shared/PanelLayout/PanelLayout';
import { Rating } from 'components/shared/Rating/Rating';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { FilterableModules } from 'shared/constants';
import { resolveFeedbackStatusColor } from 'shared/helpers';
import { useAlphamartNavigate, useAppTable, useMediaQuery } from 'shared/hooks';
import { useRemoveFeedbacks } from 'shared/mutations';
import { useGetFeedback, useGetFeedbacks } from 'shared/queries';
import { FeedbacksListItem } from 'shared/types/feedbacksListItem';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { FeedbacksFilters } from './FeedbacksFilters';
import { Comment, mainPanelStyles } from './FeedbacksList.styles';
import { RemoveFeedbacks } from './RemoveFeedbacks';
import { FeedbackDetails } from '../FeedbackDetails/FeedbackDetails';
import { messages, TypedFormattedMessage, useTypedIntl } from '../locale/messages';

export const FeedbacksListComponent = () => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const { pageSize, pageIndex } = useAppSelector(state => state.listComponent);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const { id } = useParams<{ id: string }>();
  const feedback = useGetFeedback(+id!, { enabled: !isNil(id) });

  const [enableSelection, setEnableSelection] = useState(false);
  const [selection, setSelection] = useState<Record<string, boolean>>({});
  const removeFeedbacks = useRemoveFeedbacks();

  const isMd = useMediaQuery(MEDIA_QUERY.MD);

  const savedFilters = useAppSelector(state => state.filters[FilterableModules.FEEDBACKS]);
  const feedbacks = useGetFeedbacks({
    page: pageIndex + 1,
    pageSize,
    ...savedFilters.data,
  });

  const feedbacksList = useMemo(
    () =>
      feedbacks.data?.data?.map(item => ({
        color: !isMd ? resolveFeedbackStatusColor(item.status) : '',
        ...item,
      })) ?? [],
    [isMd, feedbacks.data?.data],
  );

  const onFiltersToggle = () => setFiltersOpen(!filtersOpen);
  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const goToDetails = (item: FeedbacksListItem) => {
    navigate(`/feedback/${item.id}`);
  };

  const onSearchChanged = changed => {
    setPageIndex(0);
    dispatch(saveFilters({ filterKey: FilterableModules.FEEDBACKS, values: changed }));
  };

  const tableColumns: ColumnDef<FeedbacksListItem>[] = [
    {
      id: 'status',
      header: intl.formatMessage({ id: 'FeedbacksList.TableHeader.Status' }),
      cell: ({
        row: {
          original: { status },
        },
      }) => (
        <Status
          type={resolveFeedbackStatusColor(status)}
          value={intl.formatMessage({
            id: `Feedbacks.Filters.Status.${status}`,
          })}
          size="small"
          discrete
        />
      ),
    },
    {
      id: 'name',
      header: intl.formatMessage({ id: 'FeedbacksList.TableHeader.Name' }),
      cell: ({ row }) => `${row.original.name}`,
    },
    {
      id: 'company',
      header: intl.formatMessage({ id: 'FeedbacksList.TableHeader.Company' }),
      cell: ({ row }) => `${row.original.company}`,
    },
    {
      id: 'review',
      header: intl.formatMessage({ id: 'FeedbacksList.TableHeader.Review' }),
      cell: ({ row }) => <Rating rating={row.original.rating} />,
    },
    {
      id: 'comment',
      header: intl.formatMessage({ id: 'FeedbacksList.TableHeader.Comment' }),
      cell: ({ row }) => <Comment>{row.original.comment}</Comment>,
    },
  ];

  const table = useAppTable({
    columns: tableColumns,
    getRowCanExpand: () => false,
    data: feedbacksList,
    pageCount: Math.ceil((feedbacks.data?.count ?? 0) / pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    manualPagination: true,
    enableRowSelection: enableSelection,
    onRowSelectionChange: setSelection,
    state: {
      pagination: { pageIndex, pageSize },
      rowSelection: selection,
      columnVisibility: {
        status: isMd,
        company: isMd,
        comment: isMd,
      },
    },
  });

  return (
    <PanelLayout>
      <PanelHeader title={<TypedFormattedMessage id="FeedbacksList.Header" />} />
      <PanelContent>
        <PanelContentMain className={mainPanelStyles}>
          <SearchBar
            onSearchChanged={onSearchChanged}
            isSearchPending={feedbacks.isFetching || removeFeedbacks.isLoading}
            savedFilters={savedFilters}
            initialQuery={savedFilters.data.query}
            onFiltersToggle={onFiltersToggle}
          />
          <DataList
            table={table}
            isLoading={feedbacks.isFetching || removeFeedbacks.isLoading}
            onRowClicked={goToDetails}
            selectable={enableSelection}
          />
          <RemoveFeedbacks
            enableSelection={enableSelection}
            setEnableSelection={setEnableSelection}
            resetSelection={table.resetRowSelection}
            selection={selection}
            removeFeedbacks={removeFeedbacks}
            onRemove={feedbacks.refetch}
          />
        </PanelContentMain>
        <PanelContentSidebar
          header={intl.formatMessage({ id: 'Global.Filters' })}
          headerIcon="Filter"
          open={filtersOpen}
          onSidebarClosed={onFiltersToggle}
        >
          <FeedbacksFilters onFiltersChanged={onSearchChanged} onFiltersApplied={onFiltersToggle} />
        </PanelContentSidebar>
      </PanelContent>
      <FeedbackDetails query={feedback} onRemove={feedbacks.refetch} />
    </PanelLayout>
  );
};

export const FeedbacksList = withAlphamartIntlProvider(FeedbacksListComponent, messages);
