import React, { ReactElement, useEffect, useMemo } from 'react';
import { ButtonProps, Form, FormRow, Tooltip } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';

import { FieldCheckboxRaw } from 'components/shared/Fields/FieldCheckbox';
import { FieldMultiSelect } from 'components/shared/Fields/FieldSelect/FieldMultiSelect';
import { FieldSwitcher } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { getUserTypeLang } from 'helpers/userTypes/userTypes';
import { AccessFlag, UserTypes } from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { Status, UserFormData, UserTypeListItem } from 'shared/types';
import { fetchHedges } from 'store/hedgesSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { useTypedIntl } from '../locale/messages';

type Props = {
  context: FormikContextType<UserFormData>;
  allowUserTypesChange?: boolean;
  userTypes: UserTypeListItem[];
  controlButtons?: React.ReactNode;
};

type UserTypeSectionProps = {
  accessFlags: AccessFlag[];
  context: FormikContextType<UserFormData>;
};

const UserTypeSection = ({ accessFlags, context }: UserTypeSectionProps) => (
  <FormRow>
    {accessFlags
      .filter(access => context.values.userTypes.userAccessFlags[access])
      .map(flag => (
        <UserTypeCheckbox key={flag} access={flag} />
      ))}
  </FormRow>
);
const UserTypeCheckbox = ({ access }: { access: AccessFlag }): React.ReactElement => {
  const intl = useTypedIntl();

  return (
    <Tooltip
      label={intl.formatMessage({ id: `UserForm.Access.Flag.${access}.Description` })}
      position="top"
    >
      <FieldCheckboxRaw
        name={`userTypes.userAccessFlags.${access}`}
        data-testId={`access-flag-${access}`}
        value={access}
        disabled
        checked
      >
        {intl.formatMessage({ id: `UserForm.Access.Flag.${access}` })}*
      </FieldCheckboxRaw>
    </Tooltip>
  );
};

export const UserFormTypes = ({
  context,
  userTypes,
  controlButtons,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();

  const { values, touched, setFieldValue } = context;

  useEffect(() => {
    dispatch(
      fetchHedges(0, 0, {
        status: Status.ACTIVE,
        orderBy: HedgeOrderBy.NAME,
      }),
    );
  }, [values.info.company]);

  const userTypeOptions = useMemo(
    () =>
      userTypes.map(userType => ({
        label: getUserTypeLang(userType.name, intl),
        value: userType.id,
      })),
    [userTypes],
  );

  useEffect(() => {
    if (touched.userTypes?.privileges?.additionalDevice) return;
    setFieldValue(
      'userTypes.privileges.additionalDevice',
      values.userTypes.chosenUserTypes
        .some(userType =>
          [UserTypes.SUPER_ADMIN, UserTypes.INTERNAL_COMPANY_MANAGER].includes(userType.name),
        )
        .toString(),
    );
  }, [values.userTypes?.chosenUserTypes, touched.userTypes?.privileges?.additionalDevice]);

  const userTypeSections: Pick<UserTypeSectionProps, 'accessFlags'>[] = [
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Assay' }),
      accessFlags: [AccessFlag.ASSAY_VIEWER, AccessFlag.ASSAY_EDITOR],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Companies' }),
      accessFlags: [AccessFlag.COMPANY_MANAGER, AccessFlag.TERMS_MANAGER],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Converters' }),
      accessFlags: [
        AccessFlag.CONVERTER_VIEWER,
        AccessFlag.PARTIAL_CONVERTER_MANAGER,
        AccessFlag.FOIL_VIEWER,
        AccessFlag.SHOPPING_CART_VIEWER,
      ],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Dashboard' }),
      accessFlags: [AccessFlag.DASHBOARD_VIEWER],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Hedges' }),
      accessFlags: [
        AccessFlag.HEDGE_BASIC_VIEWER,
        AccessFlag.HEDGE_EDITOR,
        AccessFlag.HEDGE_PRICE_VIEWER,
      ],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.NonStandardConverters' }),
      accessFlags: [
        AccessFlag.NON_STANDARD_CONVERTER_VIEWER,
        AccessFlag.NON_STANDARD_CONVERTER_EDITOR,
      ],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Settings' }),
      accessFlags: [AccessFlag.SETTINGS_MANAGER],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Statistics' }),
      accessFlags: [AccessFlag.STATISTICS_VIEWER],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Users' }),
      accessFlags: [AccessFlag.USER_MANAGER],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Vehicles' }),
      accessFlags: [
        AccessFlag.VEHICLE_BASIC_VIEWER,
        AccessFlag.VEHICLE_ADVANCED_VIEWER,
        AccessFlag.VEHICLE_CREATOR,
        AccessFlag.VEHICLE_EDITOR,
      ],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Reports' }),
      accessFlags: [AccessFlag.REPORT_BASIC_GENERATOR, AccessFlag.REPORT_ADVANCED_GENERATOR],
    },
    {
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Feedbacks' }),
      accessFlags: [AccessFlag.FEEDBACK_MANAGER],
    },
  ].filter(p => p.accessFlags.some(flag => values.userTypes.userAccessFlags[flag]));

  return (
    <FormInWizard
      context={context}
      header={intl.formatMessage({ id: 'UserForm.UpdateHeader' })}
      controlButtons={controlButtons}
    >
      <Form
        headerText={intl.formatMessage({ id: 'UserForm.Section.UserTypes' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldMultiSelect
            label={intl.formatMessage({ id: 'UserForm.UserType' })}
            name="userTypes.userTypes"
            value={values.userTypes?.userTypes}
            options={userTypeOptions}
            disabled
          />
        </FormRow>
        {
          userTypeSections.map(userTypeSection => (
            <UserTypeSection context={context} {...userTypeSection} />
          )) as unknown as ReactElement
        }
      </Form>
      <Form
        headerText={intl.formatMessage({ id: 'UserForm.Privileges' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldSwitcher
            id="user-types-privileges-additional-device"
            label={intl.formatMessage({ id: 'UserForm.additionalDevice' })}
            name="userTypes.privileges.additionalDevice"
            value={values.userTypes.privileges.additionalDevice}
            options={[
              { label: intl.formatMessage({ id: 'Global.Yes' }), value: 'true' },
              { label: intl.formatMessage({ id: 'Global.No' }), value: 'false' },
            ]}
            data-cy="privileges-additional-device"
            required
          />
        </FormRow>
      </Form>
    </FormInWizard>
  );
};
