import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, ModalPortal } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { DetailsSection } from 'components/shared/Details';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { ModalFormType } from 'shared/constants';
import { AssignedVehiclesModal, hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

export const LinkToVehicle = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

const NonstandardConvertersVehicleModal = (): React.ReactElement | null => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);

  const handleCancelClick = () => {
    dispatch(hideModalForm());
  };

  useEffect(() => {
    dispatch(hideModalForm());
  }, [location]);

  if (!params) return null;

  const { assignedVehicles } = params as AssignedVehiclesModal;

  return (
    <ModalPortal
      isOpen={isOpen && modalType === ModalFormType.AssignedVehicles}
      innerPadding={false}
      header={intl.formatMessage({ id: 'NonstandardConvertersList.HeaderModal' })}
      rightButtons={[
        <Button
          onClick={handleCancelClick}
          variant="transparent"
          content="text"
          label={intl.formatMessage({ id: 'Global.Cancel' })}
        />,
      ]}
    >
      <ModalFormContent>
        {assignedVehicles.map((vehicle, index) => (
          <DetailsSection
            key={vehicle.id}
            template={[`make${index} make${index} make${index}`]}
            fields={[
              {
                name: `make${index}`,
                label: <FormattedMessage id="NonstandardConvertersList.YearMakeModel" />,
                value: (
                  <LinkToVehicle to={`/vehicles/${vehicle.id}`}>
                    <FormattedMessage
                      id="NonstandardConvertersList.Values"
                      values={{
                        year: vehicle.year,
                        make: vehicle.make,
                        model: vehicle.model,
                      }}
                    />
                  </LinkToVehicle>
                ),
              },
            ]}
          />
        ))}
      </ModalFormContent>
    </ModalPortal>
  );
};

export const NonstandardConverterVehiclesModal = withAlphamartIntlProvider(
  NonstandardConvertersVehicleModal,
  messages,
);
