import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { DataList } from 'components/shared/List';
import { SHARED } from 'shared/constants';
import { useAppTable, useMediaQuery } from 'shared/hooks';
import { ConverterMakeModel } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { StyledH5 } from './ConverterDetails.styles';
import { useTypedIntl } from '../locale/messages';

interface ConverterMakesModelsProps {
  makesModels: ConverterMakeModel[];
}

type ConverterMakeModelRow = ConverterMakeModel & { id: number };

export function ConverterMakesModels({ makesModels }: ConverterMakesModelsProps) {
  const intl = useTypedIntl();
  const isXxl = useMediaQuery(MEDIA_QUERY.XXL);

  const data = useMemo<ConverterMakeModelRow[]>(
    () => makesModels.map((mm, id) => ({ ...mm, id })),
    [makesModels],
  );

  const columns: ColumnDef<ConverterMakeModelRow>[] = [
    {
      id: 'make',
      header: intl.formatMessage({ id: 'ConverterDetails.Make' }),
      cell: ({ row }) => row.original.make?.name ?? SHARED.LONG_DASH,
    },
    {
      id: 'model',
      header: intl.formatMessage({ id: 'ConverterDetails.Model' }),
      cell: ({ row }) => row.original.model?.name ?? SHARED.LONG_DASH,
    },
    {
      id: 'year',
      header: intl.formatMessage({ id: 'ConverterDetails.Year' }),
      cell: ({ row }) => row.original.year ?? SHARED.LONG_DASH,
    },
  ];

  const table = useAppTable({
    columns,
    data,
  });

  return (
    <div>
      <StyledH5>{intl.formatMessage({ id: 'ConverterDetails.Vehicles' })}</StyledH5>
      <DataList table={table} restIndex={2} size={isXxl ? 'l' : 's'} />
    </div>
  );
}
