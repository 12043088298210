import React, { ReactElement } from 'react';
import { Table, TableCell, TableHeader, TableRow } from '@alpha-recycling/component-library';
import { ColumnDef, flexRender, Row } from '@tanstack/react-table';
import { isNil } from 'lodash';

import { Price } from 'components/shared/Price';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { SHARED, UNITS } from 'shared/constants';
import { useAppTable, useCurrentUser, useMediaQuery } from 'shared/hooks';
import { NonstandardConverterListItem } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

interface Props {
  row: Row<NonstandardConverterListItem>;
}

type NestedData = Partial<NonstandardConverterListItem> & { id: string | number };

export const TablePrices = ({ row: data }: Props) => {
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();

  const columnsPrices: ColumnDef<NestedData>[] = [
    {
      id: 'buying',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.BuyingPercentAdjustment' }),
      cell: ({ row }) => `${row.original.buyingPercentAdjustment} %`,
    },
    {
      id: 'dollar',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.DollarPriceAdjustment' }),
      cell: ({ row }) =>
        formatCurrency(row.original.dollarPriceAdjustment, 'USD', 2, currentUser.language),
    },
    {
      id: 'weightDryLbs',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.WeightDryLbs',
      }),
      cell: ({ row }) =>
        row.original.weightDryLbs ? `${row.original.weightDryLbs} ${UNITS.LBS}` : SHARED.LONG_DASH,
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.Price' }),
      cell: ({ row }) => (
        <span>
          <Price
            value={row.original.price}
            language={currentUser.language}
            currency={currentUser.currency}
          />
          {!isNil(row.original.price) && row.original.price > 0 && (
            <span> / {row.original.materialUnit} </span>
          )}
        </span>
      ),
    },
  ];

  const isDesktop = useMediaQuery(MEDIA_QUERY.MD);

  const tablePrices = useAppTable({
    columns: columnsPrices,
    data: [data.original],
    state: {
      columnVisibility: {
        price: isDesktop,
      },
    },
  });

  const { rows } = tablePrices.getRowModel();

  return (
    <Table
      header={
        <TableHeader>
          {tablePrices.getHeaderGroups()[0].headers.map(({ id, column, getContext }) => (
            <TableCell key={id}>
              {`${flexRender(column.columnDef.header, getContext()) ?? ''}`}
            </TableCell>
          ))}
        </TableHeader>
      }
      restIndex={0}
    >
      {rows.map(row => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map(cell => (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext() ?? {}) as ReactElement}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};

export const TableAssays = ({ row: data }: Props) => {
  const intl = useTypedIntl();

  const columnsAssays: ColumnDef<NestedData>[] = [
    {
      id: 'weightDryLbs',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.WeightDryLbs',
      }),
      cell: ({ row }) =>
        row.original.weightDryLbs ? `${row.original.weightDryLbs} ${UNITS.LBS}` : SHARED.LONG_DASH,
    },
    {
      id: 'platinumAssay',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.PlatinumAssay',
      }),
      cell: ({ row }) =>
        !isNil(row.original.platinumAssay) ? `${row.original.platinumAssay} ppm` : SHARED.LONG_DASH,
    },
    {
      id: 'palladiumAssay',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.PalladiumAssay',
      }),
      cell: ({ row }) =>
        !isNil(row.original.palladiumAssay)
          ? `${row.original.palladiumAssay} ppm`
          : SHARED.LONG_DASH,
    },
    {
      id: 'rhodiumAssay',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.RhodiumAssay',
      }),
      cell: ({ row }) =>
        !isNil(row.original.rhodiumAssay) ? `${row.original.rhodiumAssay} ppm` : SHARED.LONG_DASH,
    },
    {
      id: 'changedBy',
      header: intl.formatMessage({ id: 'Global.ChangedBy' }),
      cell: ({ row }) => {
        const updatedBy = row.original.updatedBy ?? row.original.createdBy;
        return updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : SHARED.LONG_DASH;
      },
    },
    {
      id: 'changedOn',
      header: intl.formatMessage({ id: 'Global.ChangedOn' }),
      cell: ({ row }) => formatDate(row.original.updatedAt ?? row.original.createdAt),
    },
  ];

  const isDesktop = useMediaQuery(MEDIA_QUERY.MD);

  const tableAssays = useAppTable({
    columns: columnsAssays,
    data: [data.original],
    state: {
      columnVisibility: {
        weightDryLbs: isDesktop,
        changedOn: isDesktop,
        changedBy: isDesktop,
      },
    },
  });

  const { rows } = tableAssays.getRowModel();

  return (
    <Table
      header={
        <TableHeader>
          {tableAssays.getHeaderGroups()[0].headers.map(({ id, column, getContext }) => (
            <TableCell key={id}>
              {`${flexRender(column.columnDef.header, getContext()) ?? ''}`}
            </TableCell>
          ))}
        </TableHeader>
      }
      restIndex={0}
    >
      {rows.map(row => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map(cell => (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext() ?? {}) as ReactElement}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};
