import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

export const dashboardStyles = css`
  display: grid;
  grid-template-rows: auto 64px 1fr;
  grid-template-columns: repeat(4, 1fr) 320px;
  gap: 24px;
  padding-top: 8px !important;
  grid-template-areas:
    'message message message message message'
    'header header header header cards'
    'chart chart chart chart cards';

  @media ${MEDIA_QUERY.MAX_XXL} {
    overflow-x: hidden;
    grid-template-rows: auto 92px 1fr auto;
    grid-template-columns: 1fr;
    gap: 0;
    grid-template-areas:
      'message'
      'header'
      'chart'
      'cards';
  }

  & > .infobar--basic {
    grid-area: message;
  }
`;

export const CardsSection = styled.div`
  grid-area: cards;
  display: flex;
  flex-direction: column;
  width: 100%;

  .single-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:after {
      display: none;
    }

    @media ${MEDIA_QUERY.MAX_XL} {
      margin-bottom: 0;
    }
  }

  @media ${MEDIA_QUERY.MAX_XXL} {
    flex-direction: row;
    max-width: calc(100vw - 32px);
  }
`;

export const CardLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;

  .indicator-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 8px;

    @media ${MEDIA_QUERY.XXL} {
      width: 60px;
      height: 60px;
      margin-right: 15px;
    }
  }

  @media ${MEDIA_QUERY.XXL} {
    font-size: 24px;
  }
`;

export const MetalName = styled.p`
  font-weight: normal;
  margin: 0;
  opacity: 0.7;

  * {
    margin: 0;
  }
`;

export const MetalPrice = styled.p`
  font-size: 13px;
  font-weight: 700;
  margin: 0;

  * {
    margin: 0;
  }

  @media ${MEDIA_QUERY.XXL} {
    font-size: 24px;
  }
`;

export const MetalPriceDiff = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  opacity: 0.7;
  font-size: 10px;
  word-break: keep-all;

  * {
    margin: 0;
  }

  & > *:first-of-type {
    margin-right: 8px;
  }

  @media ${MEDIA_QUERY.XXL} {
    font-size: 18px;
  }
`;
