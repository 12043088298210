import React, { ReactElement, useState } from 'react';
import {
  Button,
  Icon,
  Status,
  Tab,
  Table,
  TableCell,
  TableRow,
  TabList,
} from '@alpha-recycling/component-library';
import { useIsMutating } from '@tanstack/react-query';
import { ColumnDef, flexRender, Row } from '@tanstack/react-table';
import { addDays } from 'date-fns';
import { isNull } from 'lodash';

import { ActionsButton } from 'components/shared/Buttons/ActionsButton';
import { ContextMenu } from 'components/shared/ContextMenu';
import { DetailsSection } from 'components/shared/Details';
import { ItemThumbnail, ListActions } from 'components/shared/List';
import { DataList } from 'components/shared/List/DataList';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { distanceFromNow, formatDate, getDateTimeFormatForExport } from 'helpers/dateTime/dateTime';
import { Languages, ModalFormType } from 'shared/constants';
import { useAppTable, useCurrentUser, useMediaQuery } from 'shared/hooks';
import {
  ShoppingCartKeys,
  useClearShoppingCart,
  useRemoveItemFromShoppingCart,
  useRemoveItemsGroupFromShoppingCart,
  useRemoveShoppingCart,
} from 'shared/mutations';
import { useGetShoppingCartItems, useGetShoppingCarts } from 'shared/queries';
import { ItemAction, ShoppingCartItem, ShoppingCartItemsGroup } from 'shared/types';
import { downloadFile } from 'store/downloadFileSlice';
import { showModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { MEDIA_QUERY, theme } from 'theme';
import CartSelect from './CartSelect';
import RefreshButton from './RefreshButton';
import ShoppingCartPrice from './ShoppingCartPrice';
import UpdateCount from './UpdateCount';
import UpdateGroupCount from './UpdateGroupCount';
import useIsCartOpen from '../../hooks/useIsCartOpen';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../../locale/messages';
import { EditItemModal } from '../EditItemModal/EditItemModal';
import { RenameCartModal } from '../RenameCartModal/RenameCartModal';
import {
  calculateGroupTotalPrice,
  calculateTotalPrice,
  formatPartOfConverter,
  getCartSummary,
  isUsingHedgePricing,
} from '../ShoppingCart.helpers';
import {
  CartNameContainer,
  Divider,
  EditButton,
  HeaderWrapper,
  InfoContainer,
  PricesSource,
  PriceWrapper,
  StyledDetails,
  SummaryContainer,
  TableContainer,
  TableHeader,
  TitleContainer,
} from '../ShoppingCart.styles';

const WrappedNestedList =
  handleChange =>
  ({ row: shoppingCartItemsGroup }: { row: Row<ShoppingCartItemsGroup> }) => {
    const isMd = useMediaQuery(MEDIA_QUERY.MD);

    const columns: ColumnDef<ShoppingCartItem>[] = [
      {
        id: 'photo',
        cell: ({ row }) =>
          ItemThumbnail({
            photo: row.original.photo,
            attrAlt: row.original.identifier!,
          }),
      },
      {
        id: 'identifier',
        cell: ({ row }) => row.original.identifier,
      },
      {
        id: 'price',
        cell: ({ row }) => (
          <PriceWrapper>
            <ShoppingCartPrice value={calculateTotalPrice(row.original)} />
          </PriceWrapper>
        ),
      },
      {
        id: 'remove',
        cell: ({ row }) => (
          <Button
            variant="transparent"
            content="icon"
            size="small"
            iconName="Trashcan"
            data-cy={`shopping-cart-item-remove-${row.original.id}`}
            onClick={() => handleChange(row.original)}
          />
        ),
      },
    ];

    const table = useAppTable({
      columns,
      data: shoppingCartItemsGroup.original.shoppingCartItems,
      state: {
        columnVisibility: { image: isMd },
        columnSizing: {
          price: 125,
          identifier: 200,
        },
      },
    });

    const { rows } = table.getRowModel();

    return (
      <Table restIndex={0}>
        {rows.map(row => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map(cell => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext() ?? {}) as ReactElement}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    );
  };

export const ShoppingCart = (): React.ReactElement | null => {
  const intl = useTypedIntl();
  const config = useAppSelector(state => state.config.shoppingCart);
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isLg = useMediaQuery(MEDIA_QUERY.LG);

  const isOpen = useIsCartOpen();
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const clearCart = useClearShoppingCart();
  const isUsingHedge = isUsingHedgePricing(currentUser);

  const [activeCartId, setActiveCartId] = useState<number | null>(null);
  const removeShoppingCart = useRemoveShoppingCart();

  const handleUpdate = async () => {
    await Promise.all([carts.refetch(), cart.refetch()]);
  };

  const carts = useGetShoppingCarts({
    enabled: isOpen,
    onSuccess: data => isNull(activeCartId) && data[0] && setActiveCartId(data[0].id),
  });

  const cart = useGetShoppingCartItems(activeCartId!, {
    enabled: isOpen && !isNull(activeCartId),
  });

  const activeCart = carts.data!.find(cartEl => cartEl.id === activeCartId);

  const isRefreshing = useIsMutating({ mutationKey: [ShoppingCartKeys.RefreshShoppingCart] }) > 0;

  const { totalPrice, totalConverters, averagePrice, itemsInCart } = getCartSummary(
    cart.data!.shoppingCartItems,
    cart.data!.shoppingCartItemsGroups,
  );

  const handleExportCart = async () => {
    try {
      await dispatch(
        downloadFile(
          `/api/shopping-cart/${activeCartId}/export`,
          `${activeCart?.name} ${formatDate(
            new Date(),
            getDateTimeFormatForExport(currentUser?.language ?? Languages.EN),
          )}.xlsx`,
        ),
      );
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const handleRemoveItem = async (item: ShoppingCartItem) => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'ShoppingCart.Modal.ItemDelete.Confirm' }),
        confirmMessage: intl.formatMessage({ id: 'Global.Yes' }),
        closeMessage: intl.formatMessage({ id: 'Global.No' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          try {
            dispatch(hideModal());
            await removeItem.mutateAsync(item.id);
            dispatch(
              snackBarPushSuccess(
                intl.formatMessage({ id: 'ShoppingCart.Modal.ItemDelete.Success' }),
              ),
            );
            await handleUpdate();
          } catch (error) {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
        },
      }),
    );
  };

  const handleClearCart = async () => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'ShoppingCart.Modal.Clear.Confirm' }),
        confirmMessage: intl.formatMessage({ id: 'Global.Yes' }),
        closeMessage: intl.formatMessage({ id: 'Global.No' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          try {
            dispatch(hideModal());
            await clearCart.mutateAsync(activeCartId as number);
            dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'ShoppingCart.Clear.Success' })));
            handleUpdate();
          } catch (error) {
            dispatch(snackBarPushFailure(intl.formatMessage({ id: 'ShoppingCart.Clear.Error' })));
          }
        },
      }),
    );
  };

  const getNewActiveId = async (id: number): Promise<number | null> => {
    const oldActiveIndex = carts.data?.findIndex(el => el.id === id) ?? 0;
    const updatedCarts = await carts.refetch();
    const newIndex = Math.min(oldActiveIndex, (updatedCarts.data?.length ?? 0) - 1);
    return updatedCarts.data?.[newIndex]?.id ?? null;
  };

  const openRenameModal = () => {
    if (!activeCart) {
      return;
    }

    dispatch(
      showModalForm({
        modalType: ModalFormType.RenameShoppingCart,
        params: { cart: activeCart },
      }),
    );
  };

  const removeItem = useRemoveItemFromShoppingCart();

  const removeItemsGroup = useRemoveItemsGroupFromShoppingCart();
  const handleRemoveItemsGroup = async (group: ShoppingCartItemsGroup) => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'ShoppingCart.Modal.ItemsGroupDelete.Confirm' }),
        confirmMessage: intl.formatMessage({ id: 'Global.Yes' }),
        closeMessage: intl.formatMessage({ id: 'Global.No' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          try {
            dispatch(hideModal());
            await removeItemsGroup.mutateAsync(group.id);
            dispatch(
              snackBarPushSuccess(
                intl.formatMessage({ id: 'ShoppingCart.Modal.ItemsGroupDelete.Success' }),
              ),
            );
            await handleUpdate();
          } catch (error) {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
        },
      }),
    );
  };

  const handleRemoveCart = async (shoppingCart: Pick<ShoppingCartItem, 'id'>) => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'ShoppingCart.Modal.Remove.Confirm' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          try {
            dispatch(hideModal());
            await removeShoppingCart.mutateAsync({
              id: shoppingCart.id,
            });
            setActiveCartId(await getNewActiveId(shoppingCart.id));
            dispatch(
              snackBarPushSuccess(intl.formatMessage({ id: 'ShoppingCart.Modal.Remove.Removed' })),
            );
          } catch (err) {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
        },
      }),
    );
  };

  const cartActions: ItemAction[] = [
    {
      label: <FormattedMessage id="Global.Rename" />,
      onClick: () => openRenameModal(),
      dataCy: () => `rename`,
      visible: () => !isLg,
    },
    {
      label: intl.formatMessage({ id: 'ShoppingCart.Export' }),
      onClick: handleExportCart,
    },
    {
      label: intl.formatMessage({ id: 'ShoppingCart.Remove' }),
      onClick: () => {
        if (!activeCart) {
          return;
        }
        handleRemoveCart(activeCart);
      },
      dataCy: () => 'delete-shopping-cart',
    },
    {
      label: intl.formatMessage({ id: 'ShoppingCart.Clear' }),
      onClick: handleClearCart,
      dataCy: () => 'clear-cart',
      visible: !!cart.data?.shoppingCartItems.length,
    },
  ];

  const tabsOptions = carts.data!.map(cartEl => ({
    label: cartEl.name,
    value: cartEl.id,
    badge: cartEl.itemsCount,
  }));

  const itemActions: ItemAction<ShoppingCartItem>[] = [
    {
      label: intl.formatMessage({ id: 'Global.Edit' }),
      onClick: shoppingCartItem =>
        dispatch(
          showModalForm({
            modalType: ModalFormType.EditShoppingCartItem,
            params: { shoppingCartElement: shoppingCartItem, type: 'single' },
          }),
        ),
      dataCy: item => `shopping-cart-item-edit-${item?.id}`,
      visible: true,
    },
    {
      label: intl.formatMessage({ id: 'Global.Remove' }),
      onClick: handleRemoveItem,
      dataCy: item => `shopping-cart-item-remove-${item?.id}`,
      visible: true,
    },
  ];

  const groupActions: ItemAction<ShoppingCartItemsGroup>[] = [
    {
      label: intl.formatMessage({ id: 'Global.Edit' }),
      onClick: shoppingCartItemsGroup =>
        dispatch(
          showModalForm({
            modalType: ModalFormType.EditShoppingCartItem,
            params: { shoppingCartElement: shoppingCartItemsGroup, type: 'multi' },
          }),
        ),
      dataCy: item => `shopping-cart-items-group-edit-${item?.id}`,
    },
    {
      label: intl.formatMessage({ id: 'Global.Remove' }),
      onClick: handleRemoveItemsGroup,
      dataCy: group => `shopping-cart-items-group-delete-${group?.id}`,
    },
  ];

  const columns: ColumnDef<ShoppingCartItem>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Image' }),
      cell: ({ row }) => (
        <ItemThumbnail attrAlt={row.original.identifier} photo={row.original.photo} size="LARGE" />
      ),
    },
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Identifier' }),
      cell: ({ row }) => row.original.identifier,
    },
    {
      id: 'partOfConverter',
      cell: ({ row }) => (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: formatPartOfConverter(row.original.partOfConverter, intl),
          }}
        />
      ),
      header: '',
    },
    {
      id: 'unitsCount',
      header: intl.formatMessage({ id: 'ShoppingCart.TableHeader.UnitsCount' }),
      cell: ({ row }) => <UpdateCount item={row.original} onUpdate={handleUpdate} />,
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'ShoppingCart.TableHeader.Price' }),
      cell: ({ row }) => <ShoppingCartPrice value={calculateTotalPrice(row.original)} />,
    },
    {
      id: 'edit',
      cell: ({ row }) => (
        <Button
          variant="transparent"
          content="icon"
          size="small"
          iconName="Pen"
          data-cy={`shopping-cart-item-edit-${row.original.id}`}
          onClick={() =>
            dispatch(
              showModalForm({
                modalType: ModalFormType.EditShoppingCartItem,
                params: { shoppingCartElement: row.original, type: 'single' },
              }),
            )
          }
        />
      ),
      header: '',
    },
    {
      id: 'remove',
      cell: ({ row }) => (
        <Button
          variant="transparent"
          content="icon"
          size="small"
          iconName="Trashcan"
          data-cy={`shopping-cart-item-remove-${row.original.id}`}
          onClick={() => handleRemoveItem(row.original)}
        />
      ),
      header: '',
    },
    {
      id: 'actions',
      header: intl.formatMessage({ id: 'Global.Actions' }),
      cell: ({ row }) => (
        <ContextMenu
          offsetX={-60}
          trigger={
            <Button data-cy="actionButton" iconName="ThreeDots" variant="plain" content="icon" />
          }
        >
          {itemActions.map(
            action =>
              (typeof action.visible === 'function'
                ? (action.visible as (item?: ShoppingCartItem | undefined) => boolean)(row.original)
                : action.visible) && (
                <button
                  type="button"
                  onClick={() => action.onClick(row.original)}
                  key={action.id}
                  data-cy={action.dataCy?.(row.original)}
                >
                  {action.label}
                </button>
              ),
          )}
        </ContextMenu>
      ),
    },
  ];

  const table = useAppTable<ShoppingCartItem>({
    columns,
    data: cart.data!.shoppingCartItems,
    state: {
      columnVisibility: {
        image: isMd,
        edit: isLg,
        remove: isLg,
        actions: !isLg,
      },
    },
  });

  const groupColumns: ColumnDef<ShoppingCartItemsGroup>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Image' }),
      cell: ({ row }) => (
        <ItemThumbnail attrAlt={row.original.identifier} photo={row.original.photo} size="LARGE" />
      ),
    },
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Identifier' }),
      cell: ({ row }) => row.original.identifier,
    },
    {
      id: 'group',
      header: '',
      cell: () => (
        <Status
          type="inactive"
          value={intl.formatMessage({
            id: isLg ? 'ShoppingCart.AveragePrice' : 'ShoppingCart.AveragePriceShort',
          })}
          size="small"
          discrete
        />
      ),
    },
    {
      id: 'partOfConverter',
      header: '',
      cell: ({ row }) => (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: formatPartOfConverter(row.original.partOfConverter, intl),
          }}
        />
      ),
    },
    {
      id: 'unitsCount',
      header: intl.formatMessage({ id: 'ShoppingCart.TableHeader.UnitsCount' }),
      cell: ({ row }) => <UpdateGroupCount group={row.original} onUpdate={handleUpdate} />,
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'ShoppingCart.TableHeader.Price' }),
      cell: ({ row }) => <ShoppingCartPrice value={calculateGroupTotalPrice(row.original)} />,
    },
    {
      id: 'edit',
      header: '',
      cell: ({ row }) => (
        <Button
          variant="transparent"
          content="icon"
          size="small"
          iconName="Pen"
          data-cy={`shopping-cart-items-group-edit-${row.original.id}`}
          onClick={() =>
            dispatch(
              showModalForm({
                modalType: ModalFormType.EditShoppingCartItem,
                params: { shoppingCartElement: row.original, type: 'multi' },
              }),
            )
          }
        />
      ),
      meta: { widthPx: 50 },
    },
    {
      id: 'remove',
      header: '',
      cell: ({ row }) => (
        <Button
          variant="transparent"
          content="icon"
          size="small"
          iconName="Trashcan"
          data-cy={`shopping-cart-items-group-delete-${row.original.id}`}
          onClick={() => handleRemoveItemsGroup(row.original)}
        />
      ),
      meta: { widthPx: 70 },
    },
    {
      id: 'actions',
      header: intl.formatMessage({ id: 'Global.Actions' }),
      cell: ({ row }) => <ListActions actions={groupActions} item={row.original} />,
      meta: { widthPx: 70 },
    },
  ];

  const groupsTable = useAppTable({
    columns: groupColumns,
    data: cart.data!.shoppingCartItemsGroups,
    getRowCanExpand: row => !!row.original.shoppingCartItems.length,
    state: {
      columnVisibility: {
        image: isLg,
        edit: isLg,
        remove: isLg,
        actions: !isLg,
      },
    },
  });

  const isLoading =
    carts.isFetching || clearCart.isLoading || removeShoppingCart.isLoading || isRefreshing;
  const canRefresh = activeCart && activeCart.itemsCount > 0;

  const priceMismatch = activeCart && isUsingHedge !== activeCart.hedgePricing;

  if (!isOpen) return null;

  return (
    <>
      <StyledDetails
        title={
          <>
            <TitleContainer>
              <FormattedMessage id="ShoppingCart.Header" />
              {canRefresh && <RefreshButton activeCart={activeCart} onRefresh={handleUpdate} />}
            </TitleContainer>
            {priceMismatch && (
              <InfoContainer>
                <Icon name="Info" />
                <FormattedMessage id="ShoppingCart.RefreshCart.PriceMismatch" />
              </InfoContainer>
            )}
            {activeCartId && (
              <TabList
                onTabSelect={index => {
                  setActiveCartId(carts.data![index].id);
                }}
              >
                {tabsOptions.map(({ badge, label, value }) => (
                  <Tab active={value === activeCartId} countNumber={badge}>
                    {label}
                  </Tab>
                ))}
              </TabList>
            )}
          </>
        }
        backUrl="/converters"
        swipeToClose={false}
      >
        <LoadableContent loading={isLoading} mode={LoadableContentModes.OVERLAY}>
          {activeCart && (
            <>
              <TableContainer>
                <DetailsSection>
                  <TableHeader>
                    <HeaderWrapper>
                      <CartNameContainer>
                        <h3>{activeCart.name}</h3>
                        <EditButton>
                          <Button
                            variant="transparent"
                            content="icon"
                            size="small"
                            iconName="Pen"
                            data-cy="edit-button"
                            onClick={openRenameModal}
                          />
                        </EditButton>
                      </CartNameContainer>
                      <CartSelect
                        carts={carts.data!}
                        activeCartId={activeCartId}
                        setActiveCartId={setActiveCartId}
                      />
                      <PricesSource>
                        <Icon name="Info" size="small" fillColor={theme.fontColor} />
                        <FormattedMessage
                          id={`ShoppingCart.PricesSourceAndExpiration.${
                            activeCart.hedgePricing ? 'Hedge' : 'Market'
                          }`}
                          values={{
                            expiration: distanceFromNow(
                              addDays(new Date(activeCart.createdAt), config?.validityInDays ?? 0),
                            ),
                          }}
                        />
                      </PricesSource>
                      <ActionsButton actions={cartActions} />
                    </HeaderWrapper>
                  </TableHeader>
                  {!!cart.data!.shoppingCartItems.length && (
                    <DataList
                      table={table}
                      isLoading={cart.isFetching || removeItem.isLoading}
                      restIndex={1}
                    />
                  )}

                  {!!cart.data!.shoppingCartItems.length &&
                    !!cart.data!.shoppingCartItemsGroups.length && <Divider />}

                  {!!cart.data!.shoppingCartItemsGroups.length && (
                    <DataList
                      table={groupsTable}
                      isLoading={cart.isFetching || removeItem.isLoading}
                      subTables={[WrappedNestedList(handleRemoveItem)]}
                    />
                  )}

                  {!cart.data!.shoppingCartItems.length &&
                    !cart.data!.shoppingCartItemsGroups.length && (
                      <InfoContainer>
                        <Icon name="Info" />
                        <FormattedMessage id="ShoppingCart.NoItems" />
                      </InfoContainer>
                    )}
                </DetailsSection>
              </TableContainer>
              <SummaryContainer>
                <DetailsSection>
                  <div>
                    <FormattedMessage id="ShoppingCart.Summary.Header" />
                  </div>
                  <div>
                    <FormattedMessage id="ShoppingCart.Summary.ItemsInCart" />{' '}
                    <strong>{itemsInCart}</strong>
                  </div>
                  <div>
                    <FormattedMessage id="ShoppingCart.Summary.TotalConverters" />{' '}
                    <strong>{totalConverters}</strong>
                  </div>
                  <div>
                    <FormattedMessage id="ShoppingCart.Summary.AveragePrice" />{' '}
                    <ShoppingCartPrice value={averagePrice} />
                  </div>
                  <div>
                    <FormattedMessage id="ShoppingCart.Summary.TotalPrice" />{' '}
                    <ShoppingCartPrice value={totalPrice} />
                  </div>
                </DetailsSection>
              </SummaryContainer>
            </>
          )}
          {!carts.data!.length && (
            <InfoContainer>
              <Icon name="Info" />
              <FormattedMessage id="ShoppingCart.NoCarts" />
            </InfoContainer>
          )}
        </LoadableContent>
      </StyledDetails>
      <RenameCartModal />
      <EditItemModal onUpdate={handleUpdate} />
    </>
  );
};
