import React, { useEffect, useState } from 'react';
import { Button, DataOutput, OutputCell } from '@alpha-recycling/component-library';
import { capitalize } from 'lodash';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Details, DetailsSection } from 'components/shared/Details';
import { StyledLi, StyledUl } from 'components/shared/forms/Form/StyledForm';
import { Gallery } from 'components/shared/Gallery';
import { Price } from 'components/shared/Price';
import { formatDate } from 'helpers/dateTime/dateTime';
import { PERMISSIONS, SHARED, UNITS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { ItemAction, Vehicle, VehicleConverters } from 'shared/types';
import { NonstandardConverterDetails } from 'shared/types/nonstandardConverterDetails';
import { VehicleConvertersList } from './StandardConvertersList';
import { HeaderWrapper, StyledDetails, StyledMessageWrapper } from './VehicleDetails.styles';
import { VehicleNonstandardConvertersList } from './VehicleNonstandardConvertersList';
import {
  messages,
  TypedFormattedMessage,
  useTypedIntl,
  VehiclesMessages,
} from '../locale/messages';
import { getVehicleName } from '../VehiclesList/VehicleList.helpers';
import { StyledIconTooltipWarning } from '../VehiclesList/VehicleList.styles';

type VehicleParams = {
  vehicleDetails: Vehicle;
};

export const VehicleDetails = ({ vehicleDetails }: VehicleParams): React.ReactElement | null => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const currentUser = useCurrentUser();
  const [isDetailsScrollable, setIsDetailsScrollable] = useState(false);
  const [expanded, setExpanded] = useState<{ type: 'converter' | 'nonstandard'; index: number }[]>(
    [],
  );

  useEffect(() => {
    setExpanded([]);
  }, [vehicleDetails?.id]);

  const vehicleConverters = vehicleDetails?.converters as VehicleConverters[];
  const vehicleNonstandardConverters =
    vehicleDetails?.nonstandardConverters as NonstandardConverterDetails[];

  if (!vehicleDetails) return null;

  const converterFields =
    vehicleConverters &&
    vehicleConverters.flatMap(({ id, identifier, avgGroupPrice, converters }, index) => {
      const selected = expanded.some(el => el?.type === 'converter' && el?.index === index);
      return [
        {
          name: `header${index}`,
          label: '',
          value: (
            <HeaderWrapper
              onClick={() => {
                if (selected) {
                  setExpanded([
                    ...expanded.filter(el => el.index !== index || el.type !== 'converter'),
                  ]);
                } else {
                  setExpanded([...expanded, { type: 'converter', index }]);
                }
              }}
              data-cy="header-wrapper"
            >
              <Button
                variant="tonal"
                content="icon"
                size="small"
                iconName={selected ? 'Minus' : 'Plus'}
                data-cy={`vehicle-converter-expand-${id}`}
              />
              <StyledMessageWrapper>
                <TypedFormattedMessage
                  id="VehicleDetails.Value.Group.Title"
                  values={{ group: identifier }}
                />
              </StyledMessageWrapper>
              <Price
                value={avgGroupPrice}
                language={currentUser.language}
                currency={currentUser.currency}
              />
              {!converters.some(vc => vc.price.marketPrice !== null) && (
                <StyledIconTooltipWarning
                  tooltip={intl.formatMessage({
                    id: 'VehicleDetails.IncompletePrice',
                  })}
                />
              )}
            </HeaderWrapper>
          ),
        },
        {
          name: `table${index}`,
          label: '',
          value: <VehicleConvertersList data={converters} />,
          doNotRender: !selected,
        },
      ];
    });

  const nonstandardFields =
    vehicleNonstandardConverters &&
    vehicleNonstandardConverters.flatMap((section, index) => {
      const selected = expanded.some(el => el?.type === 'nonstandard' && el?.index === index);
      return [
        {
          name: `header${index}`,
          value: (
            <HeaderWrapper
              onClick={() => {
                if (selected) {
                  setExpanded([
                    ...expanded.filter(el => el.index !== index || el.type !== 'nonstandard'),
                  ]);
                } else {
                  setExpanded([...expanded, { type: 'nonstandard', index }]);
                }
              }}
              data-cy="header-wrapper"
            >
              <Button
                variant="tonal"
                content="icon"
                size="small"
                iconName={selected ? 'Minus' : 'Plus'}
                data-cy={`vehicle-nonstandard-converter-expand-${section.nonstandardConverterId}`}
              />
              <StyledMessageWrapper>
                <TypedFormattedMessage
                  id="VehicleDetails.Value.NonstandardGroup.Title"
                  values={{ group: section.material }}
                />
              </StyledMessageWrapper>
              <Price
                value={section.price}
                language={currentUser.language}
                currency={currentUser.currency}
              />
              {section.price === null && (
                <StyledIconTooltipWarning
                  tooltip={intl.formatMessage({
                    id: 'VehicleDetails.IncompletePrice',
                  })}
                />
              )}
            </HeaderWrapper>
          ),
        },
        {
          name: `table${index}`,
          value: <VehicleNonstandardConvertersList data={[section]} />,
          doNotRender: !selected,
        },
      ];
    });

  const handleUpdateView = (item: Vehicle) => {
    navigate(`/vehicles/update/${item.id}`);
  };

  const actions: ItemAction[] = [
    {
      id: 1,
      label: <TypedFormattedMessage id="Global.Update" />,
      onClick: () => handleUpdateView(vehicleDetails),
      visible: authorize(PERMISSIONS.VEHICLES.EDIT),
    },
  ];

  return (
    <AlphamartIntlProvider messages={messages}>
      <Details
        title={getVehicleName(vehicleDetails)}
        backUrl="/vehicles"
        scrollable={!isDetailsScrollable}
        actions={actions}
      >
        {vehicleDetails?.files && vehicleDetails.files.length > 0 && (
          <Gallery
            images={vehicleDetails.files}
            onLightboxToggle={isOpened => {
              setIsDetailsScrollable(isOpened);
            }}
          />
        )}

        <DataOutput
          headerText={intl.formatMessage({
            id: 'VehicleDetails.VehicleDetails.Title',
          })}
          dataTestId="vehicle-details-section"
        >
          {[
            ...(authorize(PERMISSIONS.VEHICLES.FULL_DETAILS)
              ? [
                  {
                    name: 'vin',
                    label: intl.formatMessage({
                      id: 'VehicleDetails.VehicleDetails.Vin',
                    }),
                    value: vehicleDetails.vin || SHARED.LONG_DASH,
                  },
                ]
              : []),
            {
              name: 'year',
              label: intl.formatMessage({
                id: 'VehicleDetails.VehicleDetails.Year',
              }),
              value: vehicleDetails.year,
            },
            {
              name: 'make',
              label: intl.formatMessage({
                id: 'VehicleDetails.VehicleDetails.Make',
              }),
              value: vehicleDetails.make?.name,
            },
            {
              name: 'model',
              label: intl.formatMessage({
                id: 'VehicleDetails.VehicleDetails.Model',
              }),
              value: vehicleDetails.model?.name,
            },
            {
              name: 'vehicleType',
              label: intl.formatMessage({
                id: 'VehicleDetails.VehicleDetails.VehicleType',
              }),
              value: vehicleDetails.vehicleType
                ? intl.formatMessage({
                    id: `VehicleForm.VehicleType.${capitalize(
                      vehicleDetails.vehicleType,
                    )}` as keyof VehiclesMessages,
                  })
                : SHARED.LONG_DASH,
            },
            {
              name: 'numberOfDoors',
              label: intl.formatMessage({
                id: 'VehicleDetails.VehicleDetails.NumberOfDoors',
              }),
              value: vehicleDetails.numberOfDoors || SHARED.LONG_DASH,
            },
            {
              name: 'weight',
              label: intl.formatMessage({
                id: 'VehicleDetails.VehicleDetails.Weight',
              }),
              value: vehicleDetails.weight
                ? `${vehicleDetails.weight} ${UNITS.LBS}`
                : SHARED.LONG_DASH,
            },
            ...(authorize(PERMISSIONS.VEHICLES.FULL_DETAILS)
              ? [
                  {
                    name: 'note',
                    label: intl.formatMessage({
                      id: 'VehicleDetails.VehicleDetails.Note',
                    }),
                    value: vehicleDetails.notes || SHARED.LONG_DASH,
                  },
                ]
              : []),
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              dataTestId={field.name}
              mergeCells={fields.length - 1 === index ? -(fields.length - 9) : 1}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        <DataOutput
          headerText={intl.formatMessage({
            id: 'VehicleDetails.ConverterDetails.Title',
          })}
        >
          {[
            {
              name: 'engineType',
              label: intl.formatMessage({
                id: 'VehicleDetails.EngineDetails.EngineType',
              }),
              value: intl.formatMessage({
                id: `VehicleForm.EngineType.${capitalize(
                  vehicleDetails.engineType,
                )}` as keyof VehiclesMessages,
              }),
            },
            {
              name: 'engineDisplacement',
              label: intl.formatMessage({
                id: 'VehicleDetails.EngineDetails.EngineDisplacement',
              }),
              value: vehicleDetails.engineDisplacement
                ? `${vehicleDetails.engineDisplacement} ${UNITS.CC}`
                : SHARED.LONG_DASH,
            },
            {
              name: 'transmissionType',
              label: intl.formatMessage({
                id: 'VehicleDetails.EngineDetails.TransmissionType',
              }),
              value: vehicleDetails.transmission
                ? intl.formatMessage({
                    id: `VehicleForm.TransmissionType.${capitalize(
                      vehicleDetails.transmission,
                    )}` as keyof VehiclesMessages,
                  })
                : SHARED.LONG_DASH,
            },
            {
              name: 'enginePower',
              label: intl.formatMessage({
                id: 'VehicleDetails.EngineDetails.EnginePower',
              }),
              value: vehicleDetails.enginePower
                ? `${vehicleDetails.enginePower} ${UNITS.HP}`
                : SHARED.LONG_DASH,
            },
          ].map(field => (
            <OutputCell
              key={field.name}
              dataTestId={field.name}
              mergeCells={1}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        <DataOutput
          headerText={intl.formatMessage({
            id: 'VehicleDetails.ConverterDetails.Title',
          })}
          dataTestId="converter-details-section"
        >
          {[
            {
              name: 'numberOfConverters',
              label: intl.formatMessage({
                id: 'VehicleDetails.ConverterDetails.NumberOfConverters',
              }),
              value: vehicleDetails.numberOfConverters,
            },
            {
              name: 'averageConvertersValue',
              label: intl.formatMessage({
                id: 'VehicleDetails.ConverterDetails.AverageConvertersValue',
              }),
              value: (
                <Price
                  value={vehicleDetails.avgConvertersPrice}
                  language={currentUser.language}
                  currency={currentUser.currency}
                />
              ),
            },
            ...(authorize(PERMISSIONS.VEHICLES.FULL_DETAILS)
              ? [
                  {
                    name: 'relatedConvertersIds',
                    label: intl.formatMessage({
                      id: 'VehicleDetails.ConverterDetails.RelatedConverters',
                    }),
                    value: (
                      <StyledUl>
                        {vehicleDetails.converters?.map(c => (
                          <StyledLi key={c.identifier}>{c.identifier}</StyledLi>
                        ))}
                        {vehicleDetails.converters?.map(c => (
                          <StyledLi key={c.identifier}>{c.identifier}</StyledLi>
                        ))}
                        {vehicleDetails.nonstandardConverters?.map(c => (
                          <StyledLi key={c.material}>{c.material}</StyledLi>
                        ))}
                      </StyledUl>
                    ),
                  },
                ]
              : []),
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              dataTestId={field.name}
              mergeCells={fields.length - 1 === index && fields.length === 3 ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        {authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && (
          <StyledDetails>
            <DetailsSection
              cols={4}
              title={<TypedFormattedMessage id="VehicleDetails.Converters.Group.Title" />}
              template={vehicleConverters
                .map((_, index) => [
                  `header${index} header${index} header${index} header${index}`,
                  `table${index} table${index} table${index} table${index}`,
                ])
                .flat()}
              fields={converterFields}
            />
          </StyledDetails>
        )}

        {authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && !!vehicleNonstandardConverters.length && (
          <StyledDetails>
            <DetailsSection
              cols={4}
              template={vehicleNonstandardConverters
                .map((_, index) => [
                  `header${index} header${index} header${index} header${index}`,
                  `table${index} table${index} table${index} table${index}`,
                ])
                .flat()}
              fields={nonstandardFields}
            />
          </StyledDetails>
        )}

        {authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && (
          <DataOutput
            headerText={intl.formatMessage({
              id: 'Global.MoreDetails',
            })}
            dataTestId="more-details-section"
          >
            {[
              {
                name: 'createdBy',
                label: intl.formatMessage({ id: 'Global.CreatedBy' }),
                value:
                  vehicleDetails.createdBy &&
                  `${vehicleDetails.createdBy.firstName} ${vehicleDetails.createdBy.lastName}`,
              },
              {
                name: 'createdAt',
                label: intl.formatMessage({ id: 'Global.CreatedOn' }),
                value: formatDate(vehicleDetails.createdAt),
              },
              {
                name: 'updatedBy',
                label: intl.formatMessage({ id: 'Global.UpdatedBy' }),
                value: vehicleDetails.updatedBy
                  ? `${vehicleDetails.updatedBy.firstName} ${vehicleDetails.updatedBy.lastName}`
                  : SHARED.LONG_DASH,
              },
              {
                name: 'updatedAt',
                label: intl.formatMessage({ id: 'Global.UpdatedOn' }),
                value: vehicleDetails.updatedAt
                  ? formatDate(vehicleDetails.updatedAt)
                  : SHARED.LONG_DASH,
              },
            ].map(field => (
              <OutputCell
                key={field.name}
                dataTestId={field.name}
                mergeCells={1}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>
        )}
      </Details>
    </AlphamartIntlProvider>
  );
};
