import React from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { ItemThumbnail } from 'components/shared/List';
import { DataList } from 'components/shared/List/DataList';
import { formatDate } from 'helpers/dateTime/dateTime';
import { useAppTable, useCurrentUser, useMediaQuery } from 'shared/hooks';
import { VehicleConverter } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';
import { StyledPrice } from '../VehiclesList/VehiclesList';

export const VehicleConvertersList = ({ data }: { data: VehicleConverter[] }) => {
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();
  const columns: ColumnDef<VehicleConverter>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Image' }),
      cell: ({ row }) =>
        ItemThumbnail({
          photo: row.original.converterPhoto,
          attrAlt: row.original.converterIdentifier,
        }),
    },
    {
      id: 'converterIdentifier',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Identifier' }),
      cell: ({ row }) => row.original.converterIdentifier,
    },
    {
      id: 'sampleDate',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.SampleDate' }),
      cell: ({ row }) => formatDate(row.original.sampleDate),
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Price' }),
      cell: ({ row }) =>
        StyledPrice({
          value: row.original.price.marketPrice,
          currency: currentUser.currency!,
          language: currentUser.language!,
        }),
    },
  ];

  const table = useAppTable({
    columns,
    data,
    state: { columnVisibility: { image: useMediaQuery(MEDIA_QUERY.LG) } },
  });

  return <DataList table={table} />;
};
