import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { VehicleFormShape } from '../../components/views/Vehicles/VehicleForm';
import { isTruthy } from '../../helpers/isTruthy/isTruthy';
import { apiHostname } from '../constants';
import { ExternalPhoto, parseVehicle } from '../parsers/parseVehicle';
import { AlphamartHttpError } from '../types';
import { getHttpClient } from '../utils/httpClient';

export enum VehiclesKeys {
  CreateVehicle = 'CreateVehicle',
  UpdateVehicle = 'UpdateVehicle',
}

export const useCreateVehicle = (
  options?: UseMutationOptions<void, AlphamartHttpError, VehicleFormShape>,
) =>
  useMutation(
    async params => {
      const fileIds: { data: string | ExternalPhoto; id?: number }[] = await Promise.all(
        params.photos.map(photo => {
          if ('url' in photo) {
            return getHttpClient().post<ExternalPhoto>(
              `${apiHostname}/api/upload/vehicle-image/external`,
              { url: photo.url },
            );
          }

          const formData = new FormData();
          formData.append('image', photo.file!);
          return getHttpClient().post<string>(`${apiHostname}/api/upload/vehicle-image`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );
      const vehicleDto = parseVehicle(params, fileIds);
      await getHttpClient().post(`${apiHostname}/api/vehicles`, vehicleDto);
    },
    { mutationKey: [VehiclesKeys.CreateVehicle], ...options },
  );

export const useUpdateVehicle = (
  options?: UseMutationOptions<void, AlphamartHttpError, VehicleFormShape & { id: number }>,
) =>
  useMutation(
    async ({ id, ...params }) => {
      const fileIds = await Promise.all(
        params.photos.map(photo => {
          if ('url' in photo && !('file' in photo)) {
            return getHttpClient().post<ExternalPhoto>(
              `${apiHostname}/api/upload/vehicle-image/external`,
              { url: photo.url },
            );
          }

          if (photo.id) {
            return photo;
          }

          if (!photo.file) return;
          const formData = new FormData();
          formData.append('image', photo.file);
          return getHttpClient().post<string>(`${apiHostname}/api/upload/vehicle-image`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );
      const vehicleDto = parseVehicle(params, fileIds.filter(isTruthy));
      await getHttpClient().put(`${apiHostname}/api/vehicles/${id}`, vehicleDto);
    },
    { mutationKey: [VehiclesKeys.UpdateVehicle], ...options },
  );
