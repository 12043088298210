import {
  getCoreRowModel,
  getExpandedRowModel,
  PartialKeys,
  RowData,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';

import { useGetRowId } from 'components/shared/List';

type AppRowData = RowData & { id?: string | number };

type AppTableOptions<TData extends AppRowData> = PartialKeys<
  TableOptions<TData>,
  'getRowId' | 'getCoreRowModel'
>;

export function useAppTable<TData extends AppRowData>(options: AppTableOptions<TData>) {
  const defaultOptions = {
    getCoreRowModel: getCoreRowModel<TData>(),
    getExpandedRowModel: getExpandedRowModel<TData>(),
    getRowId: useGetRowId<TData>(),
    enableRowSelection: false,
  };

  // eslint-disable-next-line no-restricted-syntax
  return useReactTable<TData>({ ...defaultOptions, ...options });
}
