import React, { ReactElement, useState } from 'react';
import { NavigationItemMobile, NavigationPanelMobile } from '@alpha-recycling/component-library';

import { AccessFlag } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useMarketToolUrls } from 'shared/hooks';
import { withAlphamartIntlProvider } from '../../AlphamartIntlProvider';
import { getExternalLink, getInternalLink } from '../helpers';
import { messages, useTypedIntl } from '../locale/messages';
import { Backdrop } from '../Navigation.styles';

export function NavigationPanelComponent() {
  const [open, setOpen] = useState(false);
  const user = useCurrentUser();
  const intl = useTypedIntl();
  const profileData = {
    name: `${user?.firstName} ${user?.lastName}`,
    companyName: user?.company.name,
  };
  const authorize = useAuthorization();
  const marketNewsUrls = useMarketToolUrls();

  const isMarketToolManager = authorize([AccessFlag.MARKET_TOOL_MANAGER]);

  return (
    <>
      <Backdrop show={open} onClick={() => setOpen(false)} />
      <NavigationPanelMobile
        open={open}
        onChangeOpen={setOpen}
        activeItemId="catalyticTool"
        headerValue={intl.formatMessage({ id: 'Navigation.YourTools' })}
        profileData={profileData}
        onClickUserProfile={() => window.location.replace(marketNewsUrls.profile)}
        isMToolsActive
        itemsGroupTop={[
          <NavigationItemMobile
            id="news"
            iconName="NewsBrand"
            getLinkItem={getExternalLink(marketNewsUrls.home)}
          >
            {intl.formatMessage({ id: 'Navigation.News' })}
          </NavigationItemMobile>,
          <NavigationItemMobile
            id="catalyticTool"
            iconName="CatalyticToolBrand"
            getLinkItem={getInternalLink('/')}
          >
            {intl.formatMessage({ id: 'Navigation.ConverterTool' })}
          </NavigationItemMobile>,
        ]}
        itemsGroupBottom={
          [
            isMarketToolManager && (
              <NavigationItemMobile
                id="manage"
                iconName="Manage"
                getLinkItem={getExternalLink(marketNewsUrls.manage)}
              >
                {intl.formatMessage({ id: 'Global.Manage' })}
              </NavigationItemMobile>
            ),
            <NavigationItemMobile id="help" iconName="Phone">
              {intl.formatMessage({ id: 'Navigation.HelpCenter' })}
            </NavigationItemMobile>,
          ].filter(Boolean) as ReactElement[]
        }
      />
    </>
  );
}

export const NavigationPanel = withAlphamartIntlProvider(NavigationPanelComponent, messages);
