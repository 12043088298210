import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { isNil, isUndefined } from 'lodash';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { Controls } from 'components/shared/forms/Wizard/Controls/Controls';
import { FormWrapper } from 'components/shared/forms/Wizard/FormWrapper/FormWrapper';
import { Wizard } from 'components/shared/forms/Wizard/Wizard';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { appendThumbnailLink } from 'helpers/watermarks/appendThumbnailLink';
import { ErrorCode } from 'shared/constants';
import { useAlphamartLocation, useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useUpdateVehicle } from 'shared/mutations/vehicles';
import { useGetVehicle } from 'shared/queries';
import { AlphamartHttpError } from 'shared/types';
import { fetchMakes } from 'store/makesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, useTypedIntl } from '../locale/messages';
import {
  vehicleFormSchema,
  VehicleFormShape,
  VehicleFormStepOne,
  VehicleFormStepTwo,
} from '../VehicleForm';

const UpdateVehicleComponent = (): React.ReactElement => {
  const { makes } = useAppSelector(state => state.makes);
  const [activeInput, setActiveInput] = useState<string | null>('');
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();
  const { fileSizeLimit, imageExtensions } = useAppSelector(
    state => state.config.upload!.vehiclePhoto,
  );
  const { id: vehicleId } = useParams<{ id: string }>();
  const {
    data: vehicleData,
    isFetching,
    isFetched,
  } = useGetVehicle(vehicleId!, { enabled: !isUndefined(vehicleId) });
  const updateVehicle = useUpdateVehicle();

  const validationSchema = useMemo(() => {
    if (isFetching) return;
    return vehicleFormSchema(imageExtensions, fileSizeLimit, makes, intl);
  }, [imageExtensions, fileSizeLimit, makes, isFetching]);

  if (isFetched && !vehicleData) navigate('/vehicles');

  const formikContext = useFormik<VehicleFormShape>({
    initialValues: {
      vin: vehicleData?.vin || '',
      makeId: vehicleData?.make?.id ?? null,
      modelId: vehicleData?.model?.id ?? null,
      year: vehicleData?.year ?? new Date().getFullYear(),
      vehicleType: vehicleData?.vehicleType || '',
      photos:
        vehicleData?.files?.map(
          ({ id, fileName, mimetype, presignedUrls, externalId, source }) =>
            ({
              file: { name: fileName, type: mimetype },
              name: fileName,
              size: 1,
              image: appendThumbnailLink(presignedUrls?.SMALL ?? ''),
              id,
              externalId,
              source,
            } as PhotoShape),
        ) || [],
      engineType: vehicleData?.engineType ?? '',
      engineDisplacement: vehicleData?.engineDisplacement ?? '',
      engineDisplacementConfirmation: vehicleData?.engineDisplacement ?? '',
      transmission: vehicleData?.transmission ?? null,
      enginePower: vehicleData?.enginePower ?? '',
      enginePowerConfirmation: vehicleData?.enginePower ?? '',
      numberOfConverters: vehicleData?.numberOfConverters ?? null,
      numberOfNonstandardConverters: vehicleData?.numberOfNonstandardConverters ?? null,
      converters:
        vehicleData?.converters?.map(({ identifier }, index) => ({
          key: `converter-${index}`,
          identifier,
          identifierConfirm: identifier,
        })) ?? [],
      nonstandardConverters:
        vehicleData?.nonstandardConverters?.map(
          ({ nonstandardConverterId, materialWeight }, index) => ({
            key: `nonstandard-converter-${index}`,
            nonstandardConverterId,
            ...(isNil(materialWeight) ? {} : { materialWeight }),
          }),
        ) || [],
      weight: vehicleData?.weight ?? '',
      weightConfirmation: vehicleData?.weight ?? '',
      numberOfDoors: vehicleData?.numberOfDoors ?? 0,
      notes: vehicleData?.notes ?? '',
    },
    enableReinitialize: true,
    initialTouched: { vin: true },
    validationSchema,
    async onSubmit(values) {
      try {
        if (!vehicleData || !vehicleId) return;
        await updateVehicle.mutateAsync({ ...values, id: +vehicleId });
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'Global.VehicleUpdate.Success' })));
        navigate(`/vehicles/${vehicleData.id}`, { state: location.state });
      } catch (e) {
        const error = e as AlphamartHttpError;
        const vinUniqueChecker = error?.response?.data.errorCode === ErrorCode.VIN_NOT_UNIQUE;
        let errorStatus: string;
        if (vinUniqueChecker) {
          errorStatus = intl.formatMessage({ id: 'Global.VehicleUpdate.VehicleExist' });
        } else {
          errorStatus = intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' });
        }
        dispatch(snackBarPushFailure(errorStatus));
      }
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    getFieldMeta,
    isValid,
    setFieldValue,
    isValidating,
  } = formikContext;

  const getErrors = (name: string): string | false => {
    const { touched: fieldTouched, error } = getFieldMeta(name);

    return (fieldTouched && error) ?? false;
  };

  useEffect(() => {
    dispatch(fetchMakes());
  }, []);

  const errorFilter = { ...formikContext.errors };
  delete errorFilter.converters;
  delete errorFilter.nonstandardConverters;
  delete errorFilter.numberOfNonstandardConverters;

  const controlButtons = (
    <Controls
      editMode
      stepsErrors={[errorFilter]}
      isValid={isValid}
      touched={touched}
      isValidating={isValidating}
      isSubmitting={isSubmitting}
      lastPageButton={intl.formatMessage({ id: 'VehicleForm.UpdateVehicle' })}
    />
  );

  return (
    <FormContainer>
      <Wizard>
        <LoadableContent loading={isFetching} mode={LoadableContentModes.FULL} drawContent>
          <FormWrapper pageIndex={1}>
            <VehicleFormStepOne
              getErrors={getErrors}
              context={formikContext}
              setActiveInput={setActiveInput}
              activeInput={activeInput}
              editMode
              controlButtons={controlButtons}
            />
          </FormWrapper>
          <FormWrapper pageIndex={2}>
            <VehicleFormStepTwo
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              getErrors={getErrors}
              context={formikContext}
              activeInput={activeInput}
              setFieldValue={setFieldValue}
              setActiveInput={setActiveInput}
              editMode
              controlButtons={controlButtons}
            />
          </FormWrapper>
        </LoadableContent>
      </Wizard>
    </FormContainer>
  );
};

export const UpdateVehicle = withAlphamartIntlProvider(UpdateVehicleComponent, messages);
