import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Notification } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface NotificationDetailsState extends GenericStoreSlice {
  notification: Notification | null;
}

const notificationDetailsSlice = createSlice<
  NotificationDetailsState,
  GenericStoreReducer<NotificationDetailsState>
>({
  name: 'notificationDetails',
  initialState: {
    notification: null,
    error: undefined,
    isPending: false,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      notification: payload,
    })),
  },
});

export const {
  pending: fetchNotificationDetailsPendingAction,
  success: fetchNotificationDetailsSuccessAction,
  failure: fetchNotificationDetailsFailureAction,
} = notificationDetailsSlice.actions;

export const fetchNotificationDetails =
  (id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchNotificationDetailsPendingAction());
      const { data } = await httpClient.get<Notification>(`${apiHostname}/api/notifications/${id}`);
      dispatch(fetchNotificationDetailsSuccessAction(data));
    } catch (error) {
      dispatch(
        fetchNotificationDetailsFailureAction(
          (error as AlphamartHttpError)?.response?.data.message,
        ),
      );
      return Promise.reject(error);
    }
  };

export const clearNotificationDetails = (): ReturnType<
  typeof fetchNotificationDetailsSuccessAction
> => fetchNotificationDetailsSuccessAction(null);

export default notificationDetailsSlice.reducer;
