import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { Controls } from 'components/shared/forms/Wizard/Controls/Controls';
import { FormWrapper } from 'components/shared/forms/Wizard/FormWrapper/FormWrapper';
import { Wizard } from 'components/shared/forms/Wizard/Wizard';
import { ErrorCode } from 'shared/constants';
import { useAlphamartLocation, useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useCreateVehicle } from 'shared/mutations/vehicles';
import { isAlphamartHttpError } from 'shared/types';
import { fetchMakes } from 'store/makesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, useTypedIntl } from '../locale/messages';
import {
  vehicleFormSchema,
  VehicleFormShape,
  VehicleFormStepOne,
  VehicleFormStepTwo,
} from '../VehicleForm';

const CreateVehicleComponent = (): React.ReactElement => {
  const { makes } = useAppSelector(state => state.makes);
  const [activeInput, setActiveInput] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();
  const vehiclePhoto = useAppSelector(state => state.config?.upload?.vehiclePhoto);
  const createVehicle = useCreateVehicle();

  const intl = useTypedIntl();
  const validationSchema = useMemo(
    () =>
      vehiclePhoto &&
      vehicleFormSchema(vehiclePhoto.imageExtensions, vehiclePhoto.fileSizeLimit, makes, intl),
    [vehiclePhoto?.imageExtensions, vehiclePhoto?.fileSizeLimit, makes],
  );

  const formikContext = useFormik<VehicleFormShape>({
    initialValues: {
      vin: '',
      make: null,
      model: null,
      year: null,
      vehicleType: '',
      photos: [],
      engineType: '',
      engineDisplacement: '',
      engineDisplacementConfirmation: '',
      transmission: '',
      enginePower: '',
      enginePowerConfirmation: '',
      numberOfConverters: 1,
      converters: [],
      numberOfNonstandardConverters: 0,
      nonstandardConverters: [],
      weight: '',
      weightConfirmation: '',
      numberOfDoors: null,
      notes: '',
    } as unknown as VehicleFormShape,
    initialTouched: { vin: true },
    validationSchema,
    onSubmit: async values => {
      try {
        await createVehicle.mutateAsync(values);
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'VehicleForm.Message.Success' })));
        navigate('/vehicles', { state: location.state });
      } catch (e) {
        let errorStatus: string;
        if (
          isAlphamartHttpError(e) &&
          e.response?.data.errorCode === ErrorCode.VEHICLE_ALREADY_EXIST
        ) {
          errorStatus = intl.formatMessage({ id: 'VehicleForm.Message.Vehicle.Error' });
        } else if (
          isAlphamartHttpError(e) &&
          e.response?.data.errorCode === ErrorCode.VIN_NOT_UNIQUE
        ) {
          errorStatus = intl.formatMessage({ id: 'VehicleForm.Message.Vin.Error' });
        } else {
          errorStatus = intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' });
        }
        dispatch(snackBarPushFailure(errorStatus));
      }
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    getFieldMeta,
    isValid,
    setFieldValue,
    isValidating,
  } = formikContext;

  const getErrors = name => {
    const { touched: fieldTouched, error } = getFieldMeta(name);

    return fieldTouched && error;
  };

  useEffect(() => {
    dispatch(fetchMakes());
  }, []);

  const errorFilter = { ...formikContext.errors };
  delete errorFilter.converters;
  delete errorFilter.nonstandardConverters;
  delete errorFilter.numberOfNonstandardConverters;

  const controlButtons = (
    <Controls
      stepsErrors={[errorFilter]}
      isValid={isValid}
      touched={touched}
      isValidating={isValidating}
      isSubmitting={isSubmitting}
      lastPageButton={intl.formatMessage({ id: 'VehicleForm.AddVehicle.Submit' })}
    />
  );

  return (
    <FormContainer>
      <Wizard>
        <FormWrapper pageIndex={1}>
          <VehicleFormStepOne
            getErrors={getErrors}
            context={formikContext}
            setActiveInput={setActiveInput}
            activeInput={activeInput}
            controlButtons={controlButtons}
          />
        </FormWrapper>
        <FormWrapper pageIndex={2}>
          <VehicleFormStepTwo
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            getErrors={getErrors}
            context={formikContext}
            activeInput={activeInput}
            setFieldValue={setFieldValue}
            setActiveInput={setActiveInput}
            controlButtons={controlButtons}
          />
        </FormWrapper>
      </Wizard>
    </FormContainer>
  );
};

export const CreateVehicle = withAlphamartIntlProvider(CreateVehicleComponent, messages);
