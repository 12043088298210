import React from 'react';
import { Icon } from '@alpha-recycling/component-library';

import {
  GridSearchHits,
  highlightableTextStyles,
  HighlightSearch,
  RowProps,
  SearchField,
  SearchFieldLabel,
  SearchGridContainer,
} from 'components/shared/List';
import { Converter, ConverterListItem } from 'shared/types';
import { theme } from 'theme';
import { useSearchableConvertersFields } from './useSearchableConvertersFields';

export const ConvertersSearchGrid = (
  props: React.PropsWithChildren<RowProps<ConverterListItem | Converter>>,
) => {
  const converter = props.row!.original;
  const searchInput = props.table!.getState().globalFilter?.trim();
  const getFields = useSearchableConvertersFields();
  const searchableFields = getFields(converter, searchInput, true);

  return (
    <SearchGridContainer>
      {!!searchableFields.length && (
        <GridSearchHits>
          <Icon name="Search" size="medium" fillColor={theme.colors.mineShaftLighter} />
          {searchableFields.map(
            field =>
              field && (
                <SearchField key={field.label}>
                  <SearchFieldLabel>{field.label}</SearchFieldLabel>
                  <HighlightSearch
                    className={highlightableTextStyles(false)}
                    searchWords={[field.matcher(searchInput)!]}
                    textToHighlight={field.value}
                  />
                </SearchField>
              ),
          )}
        </GridSearchHits>
      )}
    </SearchGridContainer>
  );
};
