import { AxiosResponse } from 'axios';
import { isUndefined } from 'lodash';

import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { VehicleFormShape } from 'components/views/Vehicles/VehicleForm';
import { checkEmptyString, checkNumber } from 'shared/checkers';
import { ApiFile } from 'shared/types';

export enum VehicleFileSource {
  UPLOAD = 'UPLOAD',
  EVOX = 'EVOX',
}

type VehicleFile = Pick<ApiFile, 'externalId' | 'fileName' | 'mimetype' | 'sort' | 'source'> & {
  id?: number | string;
};

type NonstandardConverterDto = {
  nonstandardConverterId: number | null;
  materialWeight?: number | null;
};

interface VehicleDto {
  numberOfConverters: number | null;
  numberOfNonstandardConverters: number | null;
  converters: string[];
  nonstandardConverters: NonstandardConverterDto[];
  engineDisplacement: number | null;
  enginePower: number | null;
  engineType: string | null;
  files?: VehicleFile[];
  makeId: number | null;
  modelId: number | null;
  notes: string | null;
  vehicleType: string | null;
  numberOfDoors: number | null;
  transmission: string | null;
  vin: string | null;
  weight: number | null;
  year: number | null;
}

export interface ExternalPhoto {
  storageKey: string;
  mimeType: string;
}

type FileId = PhotoShape | AxiosResponse<string | ExternalPhoto>;

const fileMapper =
  (fileIds: FileId[]) =>
  (photo: PhotoShape, index: number): VehicleFile => {
    const file = fileIds[index];

    const common = {
      fileName: photo.name!,
      sort: index,
    };

    if ('data' in file) {
      if (typeof file.data === 'string') {
        // Uploaded file
        return {
          ...common,
          externalId: file.data,
          mimetype: photo.file!.type,
          source: VehicleFileSource.UPLOAD,
        };
      }
      // File from Evox
      return {
        ...common,
        externalId: file.data.storageKey,
        mimetype: file.data.mimeType,
        source: VehicleFileSource.EVOX,
      };
    }
    // Existing file
    return {
      ...common,
      externalId: file.externalId!,
      mimetype: photo.file!.type,
      id: file.id,
      source: file.source,
    };
  };

export const parseVehicle = (vehicle: VehicleFormShape, fileIds: FileId[]): VehicleDto => {
  const numberOfConverters = checkNumber(vehicle.numberOfConverters);
  const numberOfNonstandardConverters = checkNumber(vehicle.numberOfNonstandardConverters);

  const converters = vehicle.converters
    .slice(0, (numberOfConverters ?? 0) - (numberOfNonstandardConverters ?? 0))
    .map(({ identifier }) => identifier.trim().toUpperCase());
  const nonstandardConverters = vehicle.nonstandardConverters
    .slice(0, numberOfNonstandardConverters ?? 0)
    .map(({ materialWeight, nonstandardConverterId }) => ({
      materialWeight: !isUndefined(materialWeight) ? checkNumber(materialWeight) : undefined,
      nonstandardConverterId: checkNumber(nonstandardConverterId),
    }));

  return {
    vin: checkEmptyString(vehicle.vin?.toUpperCase()),
    ...(vehicle.photos.length > 0 && {
      files: vehicle.photos.map(fileMapper(fileIds)),
    }),
    makeId: vehicle.makeId ?? null,
    modelId: vehicle.modelId ?? null,
    year: checkNumber(vehicle.year),
    vehicleType: checkEmptyString(vehicle.vehicleType),
    numberOfDoors:
      vehicle.numberOfDoors && vehicle.numberOfDoors > 0
        ? checkNumber(vehicle.numberOfDoors)
        : null,
    weight: checkNumber(vehicle.weight),
    engineType: checkEmptyString(vehicle.engineType),
    engineDisplacement: checkNumber(vehicle.engineDisplacement),
    transmission: checkEmptyString(vehicle.transmission),
    enginePower: checkNumber(vehicle.enginePower),
    notes: checkEmptyString(vehicle.notes),
    numberOfConverters,
    numberOfNonstandardConverters,
    converters,
    nonstandardConverters,
  };
};
