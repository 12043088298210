import React, { useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { DataList } from 'components/shared/List/DataList';
import { Price } from 'components/shared/Price';
import { formatDate } from 'helpers/dateTime/dateTime';
import { FeedTypes, SHARED } from 'shared/constants';
import { useAppTable, useCurrentUser, useMediaQuery } from 'shared/hooks';
import { Adjustment } from 'shared/types';
import { fetchAdjustments } from 'store/adjustmentsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { ListSection, PricesSourceInfo } from './MetalPrices.styles';
import { TypedFormattedMessage, useTypedIntl } from '../locale/messages';

export const MetalPrices = (): React.ReactElement => {
  const { adjustmentRates, isPending } = useAppSelector(state => state.adjustments);
  const currentUser = useCurrentUser();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const isLg = useMediaQuery(MEDIA_QUERY.LG);

  useEffect(() => {
    dispatch(fetchAdjustments());
  }, [dispatch]);

  const columns: ColumnDef<Adjustment>[] = [
    {
      id: 'metal',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.Metal' }),
      cell: ({ row }) => TypedFormattedMessage({ id: `Settings.Metals.${row.original.metal}` }),
    },
    {
      id: 'source',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.Source' }),
      cell: ({ row }) =>
        TypedFormattedMessage({
          id: `Settings.AdjustmentType.${row.original.adjustmentType}`,
        }),
    },
    {
      id: 'feedPrice',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.FeedPrice' }),
      cell: ({ row }) => (
        <Price
          value={row.original.feedPrice}
          language={currentUser.language}
          currency={currentUser.currency}
        />
      ),
    },
    {
      id: 'adjustment',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.Adjustment' }),
      cell: ({ row }) =>
        row.original.sourceAdjustmentType === FeedTypes.MARKET_FEED_ADJUSTED ? (
          <Price
            value={row.original.adjustmentValue}
            language={currentUser.language}
            currency={currentUser.currency}
          />
        ) : (
          SHARED.LONG_DASH
        ),
    },
    {
      id: 'totalPrice',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.TotalPrice' }),
      cell: ({ row }) => (
        <Price
          value={row.original.totalPrice}
          language={currentUser.language}
          currency={currentUser.currency}
        />
      ),
    },
    {
      id: 'updatedBy',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.UpdatedBy' }),
      cell: ({ row }) =>
        row.original.updatedBy
          ? `${row.original.updatedBy.firstName} ${row.original.updatedBy.lastName}`
          : SHARED.LONG_DASH,
    },
    {
      id: 'updatedOn',
      header: intl.formatMessage({ id: 'Global.UpdatedOn' }),
      cell: ({ row }) => formatDate(row.original.updatedAt),
    },
  ];

  const table = useAppTable({
    columns,
    data: adjustmentRates.list,
    state: {
      columnVisibility: {
        source: useMediaQuery(MEDIA_QUERY.SM),
        feedPrice: isLg,
        adjustment: isLg,
        updatedBy: useMediaQuery(MEDIA_QUERY.XL),
        updatedOn: isLg,
      },
    },
  });

  return (
    <ListSection>
      <h3>
        <TypedFormattedMessage id="Settings.Adjustments.Header.Title" />
      </h3>
      <PricesSourceInfo>
        <TypedFormattedMessage id="Settings.SourceInfo.Source" />
        <strong>{adjustmentRates.source}</strong>
        <TypedFormattedMessage id="Settings.SourceInfo.Updated" />
        <strong>{adjustmentRates.createdAt ? formatDate(adjustmentRates.createdAt) : '-'}</strong>
      </PricesSourceInfo>
      <DataList
        table={table}
        isLoading={isPending}
        size={useMediaQuery(MEDIA_QUERY.XXL) ? 'l' : 's'}
      />
    </ListSection>
  );
};
