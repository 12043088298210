import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ButtonProps, Form, FormRow, OptionsProps } from '@alpha-recycling/component-library';
import { useFormik } from 'formik';
import { capitalize, every } from 'lodash';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldPhotoRaw } from 'components/shared/Fields/FieldPhotos/FieldPhotoRaw';
import {
  FieldPhotos,
  MAX_PHOTOS_COUNT,
  PhotoShapeUnion,
} from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { WizardContext } from 'components/shared/forms/Wizard/Wizard';
import { LoadableContent } from 'components/shared/Loader';
import { getYearOptions } from 'helpers/dateTime/dateTime';
import { ENGINE_TYPES, UNITS } from 'shared/constants';
import { TRANSMISSION_TYPES } from 'shared/constants/transmissionTypes';
import { VEHICLE_TYPES } from 'shared/constants/vehicleTypes';
import { useVinImageRecognition } from 'shared/mutations/vinImageRecognition';
import { VehicleFileSource } from 'shared/parsers/parseVehicle';
import { useDecodeVin, useGetModels } from 'shared/queries';
import { useGetExternalPhoto } from 'shared/queries/externalPhoto';
import { Vehicle } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { shouldShowDecodeVinError } from './VehicleForm.helpers';
import { useTypedIntl, VehiclesMessages } from '../locale/messages';

export interface VehicleFormShape extends Vehicle {
  weightConfirmation: string | null;
  engineDisplacementConfirmation: string | null;
  enginePowerConfirmation: string | null;
  converters: {
    key: string;
    identifier: string;
    identifierConfirm: string;
  }[];
  nonstandardConverters: {
    key: string;
    nonstandardConverterId?: number;
    materialWeight?: number | null;
  }[];
  engineDisplacement: string;
  enginePower: string | null;
  engineType: string;
  makeId: number | null;
  modelId: number | null;
  notes: string;
  numberOfDoors: number | null;
  photos: PhotoShapeUnion[];
  transmission: string | null;
  vehicleType: string | null;
  vin: string;
  weight: string;
  year: number | null;
}

interface Props {
  context: ReturnType<typeof useFormik<VehicleFormShape>>;
  getErrors: (fieldName: string) => string | false | undefined;
  activeInput: string | null;
  setActiveInput: (input: string | null) => void;
  editMode?: boolean;
  controlButtons?: ReactNode;
}

const VehicleFormStepOne = ({
  getErrors,
  context,
  activeInput,
  setActiveInput,
  editMode = false,
  controlButtons,
}: Props): React.ReactElement => {
  const { handleChange, handleBlur, values, setFieldValue, setValues, setFieldTouched } = context;

  const intl = useTypedIntl();
  const yearOptions = useMemo(() => getYearOptions(), []);
  const numberOfDoorsOptions: OptionsProps[] = [];
  const dispatch = useAppDispatch();
  const { imageExtensions, fileSizeLimit } = useAppSelector(
    state => state.config.upload!.converterPhoto,
  );
  const imageRecognitionConfig = useAppSelector(state => state.config.upload!.imageRecognition);
  const vinImageRecognition = useVinImageRecognition();
  const [vinToDecode, setVinToDecode] = useState<string>('');
  const { makes } = useAppSelector(state => state.makes);
  const models = useGetModels({ makeId: values.makeId! }, { enabled: !!values.makeId });

  const makesOptions = makes?.map(make => ({ label: make.name.toUpperCase(), value: make.id }));
  const modelsOptions = (models.data ?? []).map(model => ({
    label: model.name.toUpperCase(),
    value: model.id,
  }));

  const vehicleTypeOptions = Object.values(VEHICLE_TYPES).map(vehicle => ({
    label: intl.formatMessage({
      id: `VehicleForm.VehicleType.${capitalize(
        vehicle.replace('(', '').replace(')', '').replace(/\s/g, '_'),
      )}` as keyof VehiclesMessages,
    }),
    value: vehicle,
  }));

  const engineTypeOptions = Object.values(ENGINE_TYPES).map(engine => ({
    label: intl.formatMessage({
      id: `VehicleForm.EngineType.${capitalize(
        engine.replace(/\s/g, ''),
      )}` as keyof VehiclesMessages,
    }),
    value: engine,
  }));

  const transmissionOptions = Object.values(TRANSMISSION_TYPES).map(transmission => ({
    label: intl.formatMessage({
      id: `VehicleForm.TransmissionType.${capitalize(
        transmission.replace(/\s/g, ''),
      )}` as keyof VehiclesMessages,
    }),
    value: transmission,
  }));

  for (let i = 2; i <= 6; i++) {
    numberOfDoorsOptions.push({
      value: i,
      label: i.toString(),
    });
  }

  const onBlur = e => {
    setActiveInput('');
    handleBlur(e);
  };

  const decodeVin = useDecodeVin(vinToDecode);

  useEffect(() => {
    handleDecodeVin();
  }, [decodeVin.data, decodeVin.isError]);

  const handleVinBlur = e => {
    handleBlur(e);
    if (getErrors('vin')) return;

    setVinToDecode(values.vin);
  };

  const handleDecodeVin = () => {
    if (shouldShowDecodeVinError(decodeVin, vinToDecode)) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'VehicleForm.Errors.Vin.NotFound' })));
      return;
    }
    if (!decodeVin.data) {
      return;
    }
    const {
      make,
      model,
      year,
      engineType,
      engineDisplacement,
      enginePower,
      transmission,
      vehicleType,
      numberOfDoors,
    } = decodeVin.data;

    const yearAllowed = yearOptions.some(option => option.value === year);

    setValues({
      ...values,
      makeId: make?.id ?? null,
      modelId: model?.id ?? null,
      year: yearAllowed ? year : null,
      engineType,
      engineDisplacement,
      engineDisplacementConfirmation: engineDisplacement,
      enginePower: enginePower ?? null,
      enginePowerConfirmation: enginePower,
      transmission:
        transmission
          ?.split('/')
          .find(option =>
            transmissionOptions.map(({ value }) => value).includes(option as TRANSMISSION_TYPES),
          ) ?? null,
      vehicleType:
        vehicleType
          ?.split('/')
          .find(type =>
            vehicleTypeOptions.map(({ value }) => value).includes(type as VEHICLE_TYPES),
          ) ?? null,
      numberOfDoors: numberOfDoors ?? null,
    });
  };

  const handleVinImageRecognition = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.[0]) return;

    try {
      const imageRecognitionResult = await vinImageRecognition.mutateAsync(event.target.files[0]);
      setVinToDecode(imageRecognitionResult);
      setFieldValue('vin', imageRecognitionResult);
    } catch (error) {
      dispatch(
        snackBarPushFailure(intl.formatMessage({ id: 'VehicleForm.Vin.ImageRecognition.Error' })),
      );
    }
  };

  const { contextPhotos, setContextPhotos } = useContext(WizardContext);

  const params = {
    year: values.year,
    make: makes?.find(make => make.id === values.makeId)?.name,
    model: models.data?.find(model => model.id === values.modelId)?.name,
  };

  const canFetchPhoto =
    !contextPhotos.some(photo => 'url' in photo && !('externalId' in photo)) &&
    !contextPhotos.some(photo => photo.source === VehicleFileSource.EVOX) &&
    every(params, Boolean);

  const externalPhotoQuery = useGetExternalPhoto(params, { enabled: canFetchPhoto });

  const onFetchPhoto = useCallback(async () => {
    const { data } = externalPhotoQuery;
    if (!canFetchPhoto || !data) return;
    const filename = new URL(data.url).pathname.split('/').at(-1);
    const newPhoto = {
      ...data,
      id: data.url,
      name: filename,
    };

    const newPhotosSet = [newPhoto, ...contextPhotos].slice(0, MAX_PHOTOS_COUNT);
    setContextPhotos(newPhotosSet);
    setFieldTouched('photos');
    handleChange({
      target: {
        name: 'photos',
        value: newPhotosSet,
      },
    });
  }, [params, externalPhotoQuery.data, canFetchPhoto]);

  const handleProtectedBlur = e => {
    setFieldTouched(`${e.target.name}Confirmation`);
    return onBlur(e);
  };

  useEffect(() => {
    if (!values.weight) setFieldValue('weightConfirmation', '');
    if (!values.engineDisplacement) setFieldValue('engineDisplacementConfirmation', '');
    if (!values.enginePower) setFieldValue('enginePowerConfirmation', '');
  }, [values.weight, values.engineDisplacement, values.enginePower]);

  const handleMakeChange = (value: OptionsProps) => {
    !value && context.setFieldValue('modelId', null);
  };

  const isPending = decodeVin.isFetching || vinImageRecognition.isLoading;

  const externalPhotoAvailable = !!externalPhotoQuery.data?.url;

  return (
    <LoadableContent mode={LoadableContent.MODE.FULL} drawContent loading={isPending}>
      <FormInWizard
        context={context}
        header={intl.formatMessage(
          editMode ? { id: 'VehicleForm.UpdateVehicle' } : { id: 'VehicleForm.CreateVehicle' },
        )}
        controlButtons={controlButtons}
      >
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Section.Vin' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.Vin' })}
              name="vin"
              onChange={handleChange}
              onBlur={handleVinBlur}
              value={values.vin}
              error={getErrors('vin')}
              data-cy="vin"
              onFocus={e => setActiveInput(e.target.name)}
              capitalize
              maxLength={17}
            />
            <FieldPhotoRaw
              onChange={handleVinImageRecognition}
              allowedExtensions={imageRecognitionConfig.imageExtensions}
              fileSizeLimit={imageRecognitionConfig.fileSizeLimit}
              label={intl.formatMessage({ id: 'VehicleForm.Vin.ImageRecognition.Label' })}
              name="vin-image"
            />
          </FormRow>
        </Form>
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Section.VehicleDetails' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.Make' })}
              name="makeId"
              options={makesOptions}
              value={values.makeId}
              data-cy="make"
              onFocus={e => setActiveInput(e.target.name)}
              onBlur={onBlur}
              onChange={handleMakeChange}
              required
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.Model' })}
              name="modelId"
              options={modelsOptions}
              value={values.modelId}
              data-cy="model"
              disabled={!values.makeId}
              onFocus={e => setActiveInput(e.target.name)}
              onBlur={onBlur}
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.Year' })}
              name="year"
              options={yearOptions}
              value={values.year}
              data-cy="year"
              onFocus={e => setActiveInput(e.target.name)}
              error={getErrors('year')}
              onBlur={onBlur}
              required
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.VehicleType' })}
              name="vehicleType"
              options={vehicleTypeOptions}
              value={values.vehicleType}
              data-cy="vehicle-type"
              onFocus={e => setActiveInput(e.target.name)}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.Weight' })}
              name="weight"
              onChange={handleChange}
              onBlur={handleProtectedBlur}
              value={values.weight}
              error={getErrors('weight')}
              data-cy="weight"
              suffix={UNITS.LBS}
              onFocus={e => setActiveInput(e.target.name)}
              protection={{
                hide: activeInput === 'weightConfirmation',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
              maxLength={6}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.WeightConfirmation' })}
              name="weightConfirmation"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.weightConfirmation ?? ''}
              error={getErrors('weightConfirmation')}
              data-cy="weight-confirmation"
              disabled={!values.weight}
              suffix={UNITS.LBS}
              onFocus={e => setActiveInput(e.target.name)}
              protection={{
                hide: activeInput === 'weight',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.NumberOfDoors' })}
              name="numberOfDoors"
              options={numberOfDoorsOptions}
              value={values.numberOfDoors}
              data-cy="number-of-doors"
              onFocus={e => setActiveInput(e.target.name)}
            />
          </FormRow>
        </Form>
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Section.Photos' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldPhotos
              name="photos"
              onChange={handleChange}
              onBlur={() => setFieldTouched('photos')}
              onTouch={setFieldTouched}
              error={getErrors('photos')}
              allowedExtensions={imageExtensions}
              initialPhotos={values.photos}
              data-cy="photos"
              fileSizeLimit={fileSizeLimit}
              canFetchPhoto={canFetchPhoto}
              isFetchingPhoto={externalPhotoQuery.isFetching}
              externalPhotoAvailable={externalPhotoAvailable}
              onFetchPhoto={onFetchPhoto}
            />
          </FormRow>
        </Form>
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Section.EngineDetails' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.EngineType' })}
              name="engineType"
              options={engineTypeOptions}
              value={values.engineType}
              data-cy="engine-type"
              onFocus={e => setActiveInput(e.target.name)}
              error={getErrors('engineType')}
              onBlur={onBlur}
              required
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.Transmission' })}
              name="transmission"
              options={transmissionOptions}
              value={values.transmission ?? ''}
              data-cy="transmission"
              onFocus={e => setActiveInput(e.target.name)}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.EngineDisplacement' })}
              name="engineDisplacement"
              onChange={handleChange}
              onBlur={handleProtectedBlur}
              value={values.engineDisplacement}
              error={getErrors('engineDisplacement')}
              suffix={UNITS.CC}
              data-cy="engine-displacement"
              onFocus={e => setActiveInput(e.target.name)}
              required
              protection={{
                hide: activeInput === 'engineDisplacementConfirmation',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
              maxLength={5}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.EngineDisplacementConfirmation' })}
              name="engineDisplacementConfirmation"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.engineDisplacementConfirmation ?? ''}
              error={getErrors('engineDisplacementConfirmation')}
              suffix={UNITS.CC}
              disabled={!values.engineDisplacement}
              data-cy="engine-displacement-confirmation"
              onFocus={e => setActiveInput(e.target.name)}
              required
              protection={{
                hide: activeInput === 'engineDisplacement',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.EnginePower' })}
              name="enginePower"
              onChange={handleChange}
              onBlur={handleProtectedBlur}
              value={values.enginePower || ''}
              suffix={UNITS.HP}
              error={getErrors('enginePower')}
              data-cy="engine-power"
              onFocus={e => setActiveInput(e.target.name)}
              protection={{
                hide: activeInput === 'enginePowerConfirmation',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
              maxLength={5}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.EnginePowerConfirmation' })}
              name="enginePowerConfirmation"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.enginePowerConfirmation ?? ''}
              suffix={UNITS.HP}
              disabled={!values.enginePower}
              error={getErrors('enginePowerConfirmation')}
              data-cy="engine-power-confirmation"
              onFocus={e => setActiveInput(e.target.name)}
              protection={{
                hide: activeInput === 'enginePower',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
            />
          </FormRow>
        </Form>
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Note' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldTextArea
              id="note"
              label={intl.formatMessage({ id: 'VehicleForm.Note' })}
              name="notes"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.notes}
              data-cy="notes"
              onFocus={e => setActiveInput(e.target.name)}
              maxLength={300}
            />
          </FormRow>
        </Form>
      </FormInWizard>
    </LoadableContent>
  );
};

export { VehicleFormStepOne };
