import React, { useCallback, useEffect } from 'react';
import { Button, ButtonProps, Form, FormRow } from '@alpha-recycling/component-library';
import { FormikContextType } from 'formik';
import { v4 as uuid4 } from 'uuid';

import { FieldCheckboxRaw } from 'components/shared/Fields/FieldCheckbox';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldMultiText } from 'components/shared/Fields/FieldMultiText/FieldMultiText';
import { FieldCreatableSelect } from 'components/shared/Fields/FieldSelect/FieldCreatableSelect';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldSwitcher } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { useGetConverterManufacturers } from 'shared/queries';
import { Folder } from 'shared/types';
import { createFolder } from 'store/createFolderSlice';
import { fetchFolders, success as fetchFoldersSuccessAction } from 'store/foldersSlice';
import { fetchMakes } from 'store/makesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { fetchTypes } from 'store/typesSlice';
import { ConverterMakeModel } from './ConverterMakeModel';
import { StyledH5, StyledSection } from '../AssayForm/AssayForm.style';
import { AssayFormShape, MakeModel } from '../CreateAssay/CreateAssay';
import { useTypedIntl } from '../locale/messages';

const MAKES_MODELS_LIMIT = 100;

type ConverterFormParams = {
  handleBlur: (e?: React.FocusEvent) => void;
  handleChange: (e?: React.ChangeEvent<unknown>) => void;
  values: AssayFormShape;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  touched: any;
  getErrors: (fieldName: string) => string | false | undefined;
  getArrayErrors: (fieldName: string) => string | React.ReactElement | undefined;
  context: FormikContextType<AssayFormShape>;
  onBlur: (event?: React.FocusEvent<HTMLInputElement>) => void;
  setActiveInput: (input: string) => void;
  activeInput: string | null;
  setFieldError: (fieldName: string, value: string | undefined) => void;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  setFieldTouched: (fieldName: string) => void;
  editMode?: boolean;
  controlButtons?: React.ReactNode;
};

const ConverterForm = ({
  handleBlur,
  handleChange,
  values,
  getErrors,
  getArrayErrors,
  context,
  onBlur,
  activeInput,
  setActiveInput,
  setFieldError,
  setFieldValue,
  setFieldTouched,
  touched,
  editMode = false,
  controlButtons,
}: ConverterFormParams): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const types = useAppSelector(state => state.types.types)?.map(type => ({
    value: type.id,
    label: type.name,
  }));
  const { folders } = useAppSelector(state => state.folders);

  const foldersOptions = folders?.map(folder => ({
    value: folder.id,
    label: folder.name,
  }));

  const manufacturers = useGetConverterManufacturers();
  const manufacturerOptions = (manufacturers?.data ?? []).map(manufacturer => ({
    value: manufacturer,
    label: manufacturer,
  }));

  const isPartialOptions = [
    { label: intl.formatMessage({ id: 'Global.Yes' }), value: 'true' },
    { label: intl.formatMessage({ id: 'Global.No' }), value: 'false' },
  ];

  useEffect(() => {
    if (values.converter.identifier) return;
    if (!values.converter.identifier) setFieldValue('converter.identifierConfirmation', '');
  }, [values.converter.identifier]);

  useEffect(() => {
    Promise.all([dispatch(fetchTypes()), dispatch(fetchMakes()), dispatch(fetchFolders())]);
  }, []);

  const handleFolderChange = useCallback(
    async value => {
      if (!value || typeof value?.value === 'number')
        return setFieldValue('converter.folder', value?.value);
      if (getErrors('converter.folder')) return;

      try {
        const folder = (await dispatch(createFolder(value.value))) as unknown as Folder;
        dispatch(fetchFoldersSuccessAction([...folders, folder]));
        dispatch(
          snackBarPushSuccess(intl.formatMessage({ id: 'AssaysForm.CreateFolder.Success' })),
        );
        setFieldValue('converter.folder', (folder as unknown as { id: number })?.id);
      } catch {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
    [folders, getErrors],
  );

  const validateNewFolder = val => {
    if (!val) return setFieldError('converter.folder', undefined);
    if (!touched?.converter?.folder) setFieldTouched('converter.folder');

    if (!/^[A-Z0-9 \-/()]+$/.test(val))
      return setFieldError(
        'converter.folder',
        intl.formatMessage({
          id: 'ConverterForm.Errors.Folder.NotMatchRegex',
        }),
      );

    setFieldError('converter.folder', undefined);
  };

  const handleManufacturerChange = useCallback(
    async value => setFieldValue('converter.manufacturer', value?.value),
    [],
  );

  const validateNewManufacturer = val => {
    if (!val) return setFieldError('converter.manufacturer', undefined);
    if (!touched?.converter?.manufacturer) setFieldTouched('converter.manufacturer');

    if (!/^[A-Z0-9\- /.+]+$/.test(val))
      return setFieldError(
        'converter.manufacturer',
        intl.formatMessage({
          id: 'ConverterForm.Errors.Manufacturer.NotMatchRegex',
        }),
      );

    setFieldError('converter.manufacturer', undefined);
  };

  const handleProtectedBlur = e => {
    setFieldTouched(`${e.target.name}Confirmation`);
    return onBlur(e);
  };

  const handleFolderOptionChange = () => {
    setFieldValue('converter.folder', undefined);
  };

  const handleAddMakeModel = () => {
    setFieldValue('converter.makesModels', [
      ...values.converter.makesModels,
      { key: uuid4(), makeId: null, modelId: null, year: null },
    ]);
  };

  const handleRemoveMakeModel = (makeModel: MakeModel) => {
    setFieldValue(
      'converter.makesModels',
      values.converter.makesModels.filter(({ key }) => key !== makeModel.key),
    );
  };

  const showAddMakeModelButton = values.converter.makesModels.length < MAKES_MODELS_LIMIT;

  return (
    <FormInWizard
      context={context}
      header={
        editMode
          ? intl.formatMessage({ id: 'AssaysForm.UpdateConverter' })
          : intl.formatMessage({ id: 'AssaysForm.Converter' })
      }
      controlButtons={controlButtons}
    >
      <Form
        headerText={intl.formatMessage({ id: 'ConverterForm.Section.PartDetails' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldInput
            label={intl.formatMessage({ id: 'ConverterForm.Identifier' })}
            name="converter.identifier"
            onChange={handleChange}
            onBlur={handleProtectedBlur}
            value={values.converter.identifier}
            error={getErrors('converter.identifier')}
            capitalize
            protection={{
              hide: activeInput === 'converter.identifierConfirmation',
              copy: true,
              paste: true,
            }}
            required
            data-cy="identifier"
            onFocus={e => setActiveInput(e.target.name)}
            autoComplete="off"
            maxLength={100}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'ConverterForm.IdentifierConfirmation' })}
            name="converter.identifierConfirmation"
            onChange={handleChange}
            onBlur={onBlur}
            value={values.converter.identifierConfirmation}
            error={getErrors('converter.identifierConfirmation')}
            required
            disabled={!values.converter.identifier}
            data-cy="identifier-confirmation"
            onFocus={e => setActiveInput(e.target.name)}
            capitalize
            protection={{
              hide: activeInput === 'converter.identifier',
              copy: true,
              paste: true,
            }}
            autoComplete="off"
            maxLength={100}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'ConverterForm.PartName' })}
            name="converter.partName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.converter.partName}
            error={getErrors('converter.partName')}
            data-cy="part-name"
            capitalize
            onFocus={e => setActiveInput(e.target.name)}
            autoComplete="off"
            maxLength={100}
          />
          <FieldInput
            label={intl.formatMessage({ id: 'ConverterForm.Nicknames' })}
            name="converter.nicknames"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.converter.nicknames}
            error={getErrors('converter.nicknames')}
            data-cy="nicknames"
            onFocus={e => setActiveInput(e.target.name)}
            autoComplete="off"
            maxLength={100}
          />
          <FieldMultiText
            label={intl.formatMessage({ id: 'ConverterForm.OtherNumbers' })}
            addLabel={intl.formatMessage({ id: 'ConverterForm.AddNumber' })}
            name="converter.otherNumbers"
            onChange={handleChange}
            onTouch={setFieldTouched}
            onBlur={handleBlur}
            value={values.converter.otherNumbers as unknown as { value: string }[]}
            error={getArrayErrors('converter.otherNumbers')}
            onFocus={e => setActiveInput(e.target.name)}
            capitalize
            data-cy="other-numbers"
            maxLength={100}
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'ConverterForm.Type' })}
            name="converter.type"
            options={types}
            value={values.converter.type}
            error={getErrors('converter.type')}
            required
            data-cy="type"
            onFocus={e => setActiveInput(e.target.name)}
          />
          <FieldSwitcher
            label={intl.formatMessage({ id: 'ConverterForm.IsPartial' })}
            name="converter.isPartial"
            options={isPartialOptions}
            value={values.converter.isPartial}
            data-cy="is-partial"
            required
          />
          <FieldCreatableSelect
            label={intl.formatMessage({ id: 'ConverterForm.Folder' })}
            name="converter.folder"
            options={foldersOptions}
            onChange={handleFolderChange}
            value={values.converter.folder}
            error={getErrors('converter.folder')}
            validateInput={validateNewFolder}
            required
            data-cy="folder"
            onFocus={e => setActiveInput(e.target.name)}
            onOptionChange={handleFolderOptionChange}
            maxLength={50}
          />
        </FormRow>
        <FormRow>
          <FieldCreatableSelect
            label={intl.formatMessage({ id: 'ConverterForm.Manufacturer' })}
            name="converter.manufacturer"
            options={manufacturerOptions}
            onChange={handleManufacturerChange}
            value={values.converter.manufacturer}
            error={getErrors('converter.manufacturer')}
            validateInput={validateNewManufacturer}
            dataTestId="manufacturer"
            onFocus={e => setActiveInput(e.target.name)}
            maxLength={100}
            required={false}
          />
        </FormRow>
      </Form>
      <StyledSection>
        <StyledH5>{intl.formatMessage({ id: 'ConverterForm.Section.VehiclesDetails' })}</StyledH5>
        {values.converter.makesModels.map((makeModel, index) => (
          <ConverterMakeModel
            key={makeModel.key}
            makeModel={makeModel}
            index={index}
            onRemove={handleRemoveMakeModel}
          />
        ))}
        {showAddMakeModelButton && (
          <Button
            dataTestId="add-vehicle-button"
            label={intl.formatMessage({ id: 'ConverterForm.Section.AddVehicle' })}
            variant="content"
            onClick={handleAddMakeModel}
          />
        )}
      </StyledSection>
      <Form
        headerText={intl.formatMessage({ id: 'ConverterForm.Note' })}
        headerButton={false as unknown as ButtonProps}
      >
        <FormRow>
          <FieldTextArea
            id="converter-note"
            label={intl.formatMessage({ id: 'ConverterForm.Note' })}
            name="converter.notes"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.converter.notes}
            error={getErrors('converter.notes')}
            data-cy="notes"
            onFocus={e => setActiveInput(e.target.name)}
            required={values.converter.counterfeit}
            maxLength={255}
          />
        </FormRow>
        <FormRow>
          <FieldCheckboxRaw
            name="converter.counterfeit"
            onChange={handleChange}
            checked={values.converter.counterfeit}
            data-cy="counterfeit"
            onBlur={handleBlur}
          >
            {intl.formatMessage({ id: 'ConverterForm.Counterfeit' })}
          </FieldCheckboxRaw>
        </FormRow>
      </Form>
    </FormInWizard>
  );
};

export { ConverterForm };
