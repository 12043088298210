import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { getHttpClient } from 'shared/utils/httpClient';
import { Converter, ConvertersGroup } from '../types';

export enum ConverterKeys {
  GetConverterIdentifiers = 'GetConverterIdentifiers',
  GetConverterDetails = 'GetConverterDetails',
  GetConvertersGroupDetails = 'GetConvertersGroupDetails',
  GetConverterManufacturers = 'GetConverterManufacturers',
}

interface GetConverterIdentifiersParams {
  query: string;
  limit: number;
}

export const useGetConverterIdentifiers = (
  { query, limit = 20 }: GetConverterIdentifiersParams,
  options: UseQueryOptions<string[]> = {},
) =>
  useQuery<string[]>(
    [ConverterKeys.GetConverterIdentifiers, query, limit],
    async () => {
      if (query.length < 2) return [];
      const response = await getHttpClient().get<string[]>(
        `${apiHostname}/api/converters/identifier`,
        { params: { query, limit } },
      );
      return response.data ?? [];
    },
    { initialData: [], ...options },
  );

export const useGetConverterDetails = (id?: string, options: UseQueryOptions<Converter> = {}) =>
  useQuery<Converter>(
    [ConverterKeys.GetConverterDetails, id],
    async () => {
      const response = await getHttpClient().get<Converter>(`${apiHostname}/api/converters/${id}`);
      return response.data;
    },
    options,
  );

export const useGetConvertersGroupDetails = (
  id?: string,
  options: UseQueryOptions<ConvertersGroup> = {},
) =>
  useQuery<ConvertersGroup>(
    [ConverterKeys.GetConvertersGroupDetails, id],
    async () => {
      const response = await getHttpClient().get<ConvertersGroup>(
        `${apiHostname}/api/converters/groups/${id}`,
      );
      return response.data;
    },
    options,
  );

export const useGetConverterManufacturers = (options: UseQueryOptions<string[]> = {}) =>
  useQuery<string[]>(
    [ConverterKeys.GetConverterManufacturers],
    async () => {
      const response = await getHttpClient().get<string[]>(
        `${apiHostname}/api/converters/manufacturers`,
      );
      return response.data;
    },
    options,
  );
