import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, ThresholdsType } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface ThresholdsUsage extends GenericStoreSlice {
  maxConverterSearchesPerDay: number | null;
  maxConverterViewsPerDay: number | null;
  assignedTo: number | null;
}

const thresholdsUsage = createSlice<ThresholdsUsage, GenericStoreReducer<ThresholdsUsage>>({
  name: 'thresholdsUsage',
  initialState: {
    maxConverterSearchesPerDay: null,
    maxConverterViewsPerDay: null,
    assignedTo: null,
    error: undefined,
    isPending: false,
  },
  reducers: getGenericReducers<ThresholdsUsage>(() => ({
    maxConverterSearchesPerDay: null,
    maxConverterViewsPerDay: null,
    assignedTo: null,
  })),
});

export const {
  pending: thresholdsUsageAction,
  success: thresholdsUsageSuccessAction,
  failure: thresholdsUsageFailureAction,
} = thresholdsUsage.actions;

export const setCompanyUsage =
  (type: ThresholdsType, params: ThresholdsUsage): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(thresholdsUsageAction());
      const { data } = await httpClient.put(`${apiHostname}/api/me/usages/${type}/`, params);
      dispatch(thresholdsUsageSuccessAction(data));
    } catch (error) {
      dispatch(thresholdsUsageFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default thresholdsUsage.reducer;
