import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { apiHostname } from '../constants';
import { ListModule, Model } from '../types';
import { getHttpClient } from '../utils/httpClient';

export enum ModelsKeys {
  GetModels = 'GetModels',
}

interface GetModelsParams {
  makeId: number;
  module?: ListModule;
}

export const useGetModels = (params: GetModelsParams, options: UseQueryOptions<Model[]> = {}) =>
  useQuery<Model[]>(
    [ModelsKeys.GetModels, params],
    async ({ signal }) => {
      const response = await getHttpClient().get<Model[]>(`${apiHostname}/api/models`, {
        params,
        signal,
      });
      return response.data;
    },
    {
      initialData: [],
      ...options,
    },
  );
