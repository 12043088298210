import React, { useMemo } from 'react';
import { Form, FormRow } from '@alpha-recycling/component-library';

import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { getYearOptions } from 'helpers/dateTime/dateTime';
import { useGetModels } from 'shared/queries';
import { useAppSelector } from 'store/shared/hooks';
import { MakeModel } from '../CreateAssay/CreateAssay';
import { useTypedIntl } from '../locale/messages';

export interface ConverterMakeModelProps {
  makeModel: MakeModel;
  index: number;
  onRemove(makeModel: MakeModel): void;
}

export function ConverterMakeModel({ makeModel, index, onRemove }: ConverterMakeModelProps) {
  const intl = useTypedIntl();
  const { makes } = useAppSelector(state => state.makes);
  const yearOptions = useMemo(() => getYearOptions(), []);
  const models = useGetModels({ makeId: makeModel.makeId! }, { enabled: !!makeModel.makeId });

  const makesOptions = (makes ?? []).map(make => ({
    label: make.name.toUpperCase(),
    value: make.id,
  }));

  const modelsOptions = models.data!.map(model => ({
    label: model.name.toUpperCase(),
    value: model.id,
  }));

  return (
    <Form
      headerText={intl.formatMessage(
        { id: 'ConverterForm.Section.VehicleHeader' },
        { n: index + 1 },
      )}
      headerButton={{
        label: intl.formatMessage({ id: 'Global.Remove' }),
        iconName: 'Trashcan',
        variant: 'plain',
        size: 'medium',
        onClick: () => onRemove(makeModel),
      }}
    >
      <FormRow>
        <FieldSelect
          label={intl.formatMessage({ id: 'ConverterForm.Make' })}
          name={`converter.makesModels.${index}.makeId`}
          options={makesOptions}
          value={makeModel.makeId}
          data-cy={`make-id-${index}`}
        />
        <FieldSelect
          label={intl.formatMessage({ id: 'ConverterForm.Model' })}
          name={`converter.makesModels.${index}.modelId`}
          options={modelsOptions}
          value={makeModel.modelId}
          data-cy={`model-id-${index}`}
          disabled={!makeModel.makeId}
        />
        <FieldSelect
          label={intl.formatMessage({ id: 'ConverterForm.Year' })}
          name={`converter.makesModels.${index}.year`}
          options={yearOptions}
          value={makeModel.year}
          data-cy={`year-${index}`}
        />
      </FormRow>
    </Form>
  );
}
