import { createSlice } from '@reduxjs/toolkit';

import { apiHostname, SUPPORTED_PORTAL_CURRENCIES } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import { CurrencyAdjustment } from 'shared/types/currencyAdjustment';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface CurrencyAdjustmentState extends GenericStoreSlice {
  currencyAdjustment: CurrencyAdjustment;
}

const currencyAdjustmentsSlice = createSlice<
  CurrencyAdjustmentState,
  GenericStoreReducer<CurrencyAdjustmentState>
>({
  name: 'currencyAdjustments',
  initialState: {
    currencyAdjustment: {} as CurrencyAdjustment,
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({ currencyAdjustment: payload } || {})),
  },
});

export const {
  success: fetchCurrencyAdjustmentSuccessAction,
  failure: fetchCurrencyAdjustmentFailureAction,
  pending: fetchCurrencyAdjustmentAction,
} = currencyAdjustmentsSlice.actions;

export default currencyAdjustmentsSlice.reducer;

export const fetchCurrencyAdjustment =
  (currency: SUPPORTED_PORTAL_CURRENCIES): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      await dispatch(fetchCurrencyAdjustmentAction());
      const { data } = await httpClient.get(
        `${apiHostname}/api/exchange-rates/adjustments/${currency}`,
      );
      dispatch(fetchCurrencyAdjustmentSuccessAction(data));
    } catch (error) {
      dispatch(
        fetchCurrencyAdjustmentFailureAction((error as AlphamartHttpError)?.response?.data.message),
      );
      return Promise.reject(error);
    }
  };
