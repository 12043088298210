import { isNil } from 'lodash';

import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useMediaQuery } from 'shared/hooks';
import { matchNormalized, matchSampleNameMultiWord } from 'shared/matchers';
import { Converter, ConverterListItem } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

export interface SearchField {
  label: string;
  matcher: (input?: string) => RegExp | undefined;
  value: string;
}

export const useSearchableConvertersFields = () => {
  const intl = useTypedIntl();
  const auth = useAuthorization();
  const seeSampleName = auth(PERMISSIONS.CONVERTERS.LIST_SAMPLE);
  const isMaxXL = useMediaQuery(MEDIA_QUERY.MAX_XL);
  const isMaxLG = useMediaQuery(MEDIA_QUERY.MAX_LG);

  return (converter: Converter | ConverterListItem, searchInput: string, isGrid?: boolean) => {
    const fields: SearchField[] = [];

    if (isMaxXL || isGrid) {
      fields.push({
        label: intl.formatMessage({ id: 'ConvertersList.TableHeader.Vehicle' }),
        matcher: matchNormalized,
        value: [converter.year, converter.make, converter.model].filter(Boolean).join(' '),
      });
    }

    if ((isMaxLG || isGrid) && seeSampleName) {
      fields.push({
        label: intl.formatMessage({ id: 'ConverterDetails.Assay.SampleName' }),
        matcher: matchSampleNameMultiWord,
        value:
          (converter as Converter).assay?.sampleName ?? (converter as ConverterListItem).sampleName,
      });
    }

    fields.push(
      {
        label: intl.formatMessage({ id: 'ConverterDetails.Nicknames' }),
        matcher: matchNormalized,
        value: converter.nicknames,
      },
      {
        label: intl.formatMessage({ id: 'ConverterDetails.OtherNumbers' }),
        matcher: matchNormalized,
        value: converter.otherNumbers?.join(' ') ?? '',
      },
      {
        label: intl.formatMessage({ id: 'ConverterDetails.PartName' }),
        matcher: matchNormalized,
        value: converter.partName,
      },
    );

    converter.makesModels?.forEach(({ make, model }) => {
      fields.push(
        {
          label: intl.formatMessage({ id: 'ConverterDetails.Make' }),
          matcher: matchNormalized,
          value: make?.name ?? '',
        },
        {
          label: intl.formatMessage({ id: 'ConverterDetails.Model' }),
          matcher: matchNormalized,
          value: model?.name ?? '',
        },
      );
    });

    return fields.filter(
      p => !!p && !isNil(p.value) && !!searchInput && p.matcher(searchInput)?.test(p.value),
    );
  };
};
