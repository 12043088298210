import React from 'react';
import { Textarea, TextareaProps } from '@alpha-recycling/component-library';

export interface FieldTextareaProps extends Omit<TextareaProps, 'error' | 'labelText'> {
  'data-cy'?: string;
  error?: string | boolean;
  label?: string;
}
export const FieldTextArea = ({
  label,
  error,
  'data-cy': dataCy,
  flex = true,
  ...props
}: FieldTextareaProps) => (
  <Textarea
    flex={flex}
    labelPlacement="top"
    labelText={label}
    error={!!error}
    {...(!!error && { errorMessage: String(error) })}
    data-cy={dataCy}
    {...props}
  />
);
